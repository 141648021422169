import Improvement from "../components/shared/Improvement";
import Investment from "../components/shared/Investment";
import Attachments from "../components/shared/Attachments";
import Header from "../components/shared/Header";
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { theme } from "../components/config/Config";
import { getFormDataById } from "../redux/slices/getFormDataSlice";
import { ThemeProvider } from "@mui/material/styles";
import CapAuthActionButtons from "../components/actionButtons/ActionButtons";
import Authorization from "../components/shared/Authorization";
import ProjectsDetailSection from "../components/shared/ProjectsDetailSection";
import ModProjectDetails from "../components/shared/ModProjectDetails";
import Loader from "../components/utils/Loader";


const ModificationAuthorization = (props: any) => {

  const formData = useAppSelector((state) => state.getFormData);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getFormDataById(id))
    }
  }, [id]);

  return (
    <>
      {!formData?.loading ? <div className="form-dv form-field-label">
        <ThemeProvider theme={theme}>
          <Header />
          <Investment />
          <ModProjectDetails/>
          <Attachments />
          <Authorization authType = {"Modifications"}/>
          <CapAuthActionButtons />
          <Loader/>
        </ThemeProvider>

      </div> : <div className="loading-backdrop loading-backdrop-full">
        <CircularProgress />
      </div>}
    </>
  );
};

export default ModificationAuthorization;
