import { useState } from "react";
import MenuItems from "./menuItems";
import { menuItemsAdminData, menuItemsManagerData, menuItemsExternalUserData } from "./menuItemsData";
import CreateAuth from "../../components/utils/CreateAuth";
import Button from "@mui/material/Button";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  getInvdetailsdata,
  getinvdetails,
} from "../../redux/slices/createAuthSlice";
import { getuserdetailsdata } from "../../redux/slices/userContextDetailSlice";
import AddIcon from "@mui/icons-material/Add";


const Navbar = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector(getInvdetailsdata);
  const userdata = useAppSelector(getuserdetailsdata);

  const handleClickOpen = () => {
    if (!data) {
      dispatch(getinvdetails());
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Property manager internal - external who to show the reports section
  return (
    <>
      <nav className="desktop-nav">
        <ul className="menus">
          {userdata?.isAdmin
            ?
            menuItemsAdminData?.map((menu, index) => {
              return <MenuItems items={menu} key={index} />;
            })
            : !userdata?.isExternal ?
              menuItemsManagerData.map((menu, index) => {
                return <MenuItems items={menu} key={index} />;
              }) :
              menuItemsExternalUserData.map((menu, index) => {
                return <MenuItems items={menu} key={index} />;
              })
          }
        </ul>
      </nav>
      <div className="new-auth-dv">
        {userdata?.showNewAuthButton ? (
          <Button
            variant="contained"
            style={{ backgroundColor: "#0263E0" }}
            onClick={handleClickOpen}
            color="primary"
            className="new-auth-btn"
          >
            <AddIcon sx={{ fontSize: "1rem", marginRight: "2.5px", marginLeft: "-4px" }} /> New Auth
          </Button>
        ) : (
          <></>
        )}
        <CreateAuth open={open} handleclose={handleClose} setAuthId={props.setAuthId} />
      </div>
    </>
  );
};

export default Navbar;
