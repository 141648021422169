import React, { useEffect } from 'react';
import { useAppState } from './authorizationSectionHelper/useStateHooks';

const LeaseAndPropAuthSection = (props:any) => {
    const appState = useAppState();
    useEffect(() => {
        if (props?.constants.isAssetManagerWithEditAccess) {
            props.localState.authApproversRequest.terminationFee = appState.proposaldata?.data?.TerminationFee
            props.localState.authApproversRequest.terminationTime = appState.proposaldata?.data?.TerminationTime
            props.localState.authApproversRequest.amount = 0
            props.localState.authApproversRequest.marketRate = null
          if (props?.constants.terminationTimeNotEmpty && props?.constants.terminationfeeNotEmpty) {
            props?.fetchApprovers();
            props?.localState.setShowApprovers(true);
          } else {
            props?.localState.setShowApprovers(false);
          }
    
        }
      }, [appState?.proposaldata?.data?.TerminationFee, appState?.proposaldata?.data?.TerminationTime]);

  return null;
};

export default LeaseAndPropAuthSection;