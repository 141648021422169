import { useMemo } from 'react';
import { toLower } from "lodash";

export const useAuthorizationConstants = (formData: any, AuthData: any, formType: any, userdata: any, budgetedData: any, nonBudgetedData: any, ModProjectDetailData: any, proposaldata: any, leaseProjectDetails: any, authorizationData:any) => {
  const constants = useMemo(() => ({
    isNAA_CA: formData?.fundType === "NAA" && toLower(formData?.parentComponent) === "captialauthorization",

    isTIAA: formData?.fundType === "TIAA",

    isAuthDataValuationPer: authorizationData.isAmtHigherThanValuationPercentage !== null && authorizationData.isAmtHigherThanValuationPercentage !== undefined,
    
    isAmtHigherDefined: formData?.isAmtHigherThanValuationPercentage !== null &&
      formData?.isAmtHigherThanValuationPercentage !== undefined,

    isAuthAmountValid: formData?.authAmount !== null &&
      formData?.authAmount !== undefined &&
      formData?.authAmount > 0,

    isBudgeted: formType === true,

    isNonOmni: formData?.propertyManagementCompanyName !== "OMNI",

    isAuthBudgedNonBudgetValid : nonBudgetedData.data.authorizationAmount !== undefined || budgetedData.data.authAmount !== undefined,
    
    isBudgetedAuthAmountValid: budgetedData.data.authAmount !== undefined &&
      budgetedData.data.authAmount > 0,

    isNonBudgetedAuthAmountValid: nonBudgetedData.data.authorizationAmount !== undefined &&
      nonBudgetedData.data.authorizationAmount > 0,

    isAssetManagerWithEditAccess: userdata?.isAssetManager && (formData?.isFormEditable || formData?.showApprovers),

    isModEcotermValid: (
      typeof ModProjectDetailData.ecoTerms === 'string' &&
      ['yes', 'no'].includes(ModProjectDetailData.ecoTerms.toLowerCase())
    ),

    isModAuthAmountValid: ModProjectDetailData.authAmount !== "undefined" &&
      ModProjectDetailData.authAmount !== undefined &&
      ModProjectDetailData.authAmount !== "0" &&
      ModProjectDetailData.authAmount !== 0 &&
      ModProjectDetailData.authAmount !== "",

    terminationTimeNotEmpty: proposaldata?.data?.TerminationTime !== undefined &&
      proposaldata?.data?.TerminationTime !== null && proposaldata?.data?.TerminationTime !== "",

    terminationfeeNotEmpty: proposaldata?.data?.TerminationFee !== undefined &&
      proposaldata?.data?.TerminationFee !== null && proposaldata?.data?.TerminationTime !== "",

    leaseTermsIsNotEmpty: (leaseProjectDetails.leaseTerms !== null && leaseProjectDetails.leaseTerms !== undefined &&
      !Number.isNaN(leaseProjectDetails.leaseTerms) && leaseProjectDetails.leaseTerms > 0),

    leasePremiseIsNotEmpty: (leaseProjectDetails.leasePremise !== null && leaseProjectDetails.leasePremise !== undefined &&
      !Number.isNaN(leaseProjectDetails.leasePremise) && leaseProjectDetails.leasePremise > 0),

    leaseMarketRateIsNotNull: leaseProjectDetails.leaseMarketRate !== null && leaseProjectDetails.leaseMarketRate !== undefined &&
      leaseProjectDetails.leaseMarketRate !== "",

    leaseAmountNotNull: (leaseProjectDetails.amount !== null && leaseProjectDetails.amount !== undefined &&
      !Number.isNaN(leaseProjectDetails.amount) && leaseProjectDetails.amount > 0),

  }), [formData, AuthData, formType, userdata, budgetedData, nonBudgetedData,nonBudgetedData.data.authorizationAmount, ModProjectDetailData, proposaldata, leaseProjectDetails]);

  return constants;
};