
import { createSlice } from "@reduxjs/toolkit";
import { proposaldata } from "../../interfaces/Interfaces";

interface InitialState {
    data: proposaldata,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as proposaldata,
    error: '',
    loading: false
}

const proposalDataSlice = createSlice(
    {
        name: 'proposalData',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as proposaldata;
                state.error = "";
                state.loading = false;
            },
            budgResetStateInImprovement: (state) => {
                state.data = {} as proposaldata;
                state.error = "";
                state.loading = false;
            },
            updateproposalData(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updateproposalData, resetState: resetProposalDataStateAction, budgResetStateInImprovement } = proposalDataSlice.actions;
export default proposalDataSlice.reducer;



