import React from 'react';
import { Grid, TextField } from '@mui/material';

const ApproverReadOnlyField: React.FC<any> = ({ index, value }) => {
  return (
    <Grid item xs={12} md={6} key={index}>
      <Grid>
        <label>Approver {index + 1}</label>
      </Grid>
      <Grid>
        <TextField disabled={true} fullWidth size="small" value={value.name}></TextField>
      </Grid>
      <Grid className="grid-padding-level">
        <TextField disabled={true} fullWidth size="small" value={value.level}></TextField>
      </Grid>
    </Grid>
  );
};

export default ApproverReadOnlyField;
