import { createSlice } from "@reduxjs/toolkit";
import { errorFields } from "../../interfaces/Interfaces";

interface InitialState {
    data: errorFields,
    error: string,
    loading: boolean
}

var initialData = {
    propManager: false,
    assetManager: false,
    projectType: false,
    nbProjectType: false,
    selectProject: false,
    bid: false,
    nbBid: false,
    authAmount: false,
    nbAuthAmount: false,
    exceededAuthAmount: false,
    bidReason: false,
    nbBidReason: false,
    nonBudgetReason: false,
    authAmountError:false,
    nbProjectName: false,
    nbProjectId: false,
    projMangFee: false,
    projManFeeValue: false,
    projDetailAuthAmount: false,
    budgetedstartdate: false,
    budgetedenddate: false,
    nbBudgetedstartdate: false,
    nbBudgetedenddate: false,
    propertyProjectDetail: [],
    approversListError: [],
    
    leaseTerms: false,
    leasePremises: false,
    leaseMarketRate: false,
    leaseAmount: false,
    leaseOFAC: false,
    
    finalCheck: true,
    modAmount:false,
    modEcoterms: false,
    appointmentterminationfee : false,
    appointemnetterminationtime : false,
    appprojectname : false,
    modprojectname : false

}

const initialState: InitialState =
{
    data: { ...initialData } as errorFields,
    error: '',
    loading: false
}

const submitFormErrorSlice = createSlice(
    {
        name: 'nonBudgetedData',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = { ...initialData } as errorFields;
                state.error = "";
                state.loading = false;
            },
            updateErrorFields(state, action) {
                state.data = action.payload;
            },
            submitErrorCheck(state) {
                state.data.finalCheck = (state.data.propManager || state.data.assetManager || state.data.projectType || state.data.selectProject || state.data.bid || state.data.authAmount || state.data.exceededAuthAmount || state.data.bidReason || state.data.nonBudgetReason || state.data.nbProjectName || state.data.nbProjectId || state.data.projMangFee || state.data.projManFeeValue || state.data.projDetailAuthAmount || state.data.budgetedstartdate || state.data.budgetedenddate || state.data.nbBudgetedstartdate || state.data.nbBudgetedenddate);
            }

        },

    }
);
export const { updateErrorFields, resetState: resetSubmitFormErrorStateAction } = submitFormErrorSlice.actions;
export default submitFormErrorSlice.reducer;
