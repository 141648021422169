import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridFilterModel,
  GridHeaderFilterCellProps,
  GridLoadingOverlay,
  GridPaginationModel,
  GridRenderHeaderFilterProps,
  GridSortModel,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import dashboardViewsColumnConfig from "../dashboard/dashboardViewsColumn.json";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { DashboardService } from "../../services/DashboardService";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { debounce } from "lodash";
import _debounce from 'lodash/debounce';
import { dashboardDropdownData } from "../../redux/slices/dashboardDropdownSlice";
import { getuserdetailsdata } from "../../redux/slices/userContextDetailSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { postApiCall } from "../../services/ApiHelper";
import { baseUrl } from "../config/Config";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { useNavigate } from "react-router-dom";
import { authData, resetAuthDataStateAction } from "../../redux/slices/authDataSlice";
import { resetAuthorizationDataStateAction } from "../../redux/slices/authorizationDataSlice";
import { resetBudgetedDataStateAction } from "../../redux/slices/budgetedDataSlice";
import { resetNonBudgetedDataStateAction } from "../../redux/slices/nonBudgetedDataSlice";
import { resetprojectPropertyDetailDataStateAction } from "../../redux/slices/propertyProjectDetailSlice";
import { resetSubmitFormErrorStateAction } from "../../redux/slices/submitFormErrorSlice";
import { resetProposalDataStateAction } from "../../redux/slices/proposalSlice";
import { resetModprojectDetailDataStateAction } from "../../redux/slices/modProjectDetailSlice";
import { resetleaseProjectDetailStateAction } from "../../redux/slices/leaseProjectDetailSlice";
import { resetJVReasonForAuthStateAction } from "../../redux/slices/jvAuthReasonSlice";
import { resetFormDataStateAction } from "../../redux/slices/getFormDataSlice";
import * as XLSX from 'xlsx';
import { toast } from "../../interfaces/Interfaces";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from "moment";
import { ToastMessage } from "../utils/ToastMessage";
import { LoadingButton } from "@mui/lab";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const localStoragePayload = "dashBoardPayload";

const DashboardDataGrid = (props: any) => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const dashboardService = new DashboardService();
  const [rows, setRows] = useState<any>([]);
  const [headingFilters, setHeadingFilters] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);

  const [valueDb, setValueDb] = useState<any>({});
  const [authNameDropdowns, setAuthNameDropdowns] = useState<any>({});

  const [storedTab, setStoredTab] = useState<any>();
  const [isActive, setIsActive] = useState<any>({
    Value: true,
  });
  const userdata = useAppSelector(getuserdetailsdata);
  const [excelDownloading, setExcelDownloading] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [openRestorePopup, setOpenRestorePopup] = React.useState(false);
  const [deleteauthorization, setDeleteauthorization] = React.useState({
    id: "",
    isDelete: false,
  });

  const [restoreauthorization, setRestoreAuthorization] = React.useState({
    id: "",
    isDelete: true,
  });

  const [toast, setToast] = useState(false);

  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  const handleClickOpenDeletePopup = (props: any) => {
    setDeleteauthorization({
      id: props.authFormID,
      isDelete: true,
    });
    setOpenDeletePopup(true);
  };

  const handleCloseRestorePopup = () => {
    setOpenRestorePopup(false);
  };

  const handleClickOpenRestorePopup = (props: any) => {
    setRestoreAuthorization({
      id: props.authFormID,
      isDelete: false,
    });
    setOpenRestorePopup(true);
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });

  const [sortedModel, setSortedModel] = useState({
    ascSort: false,
    sortedColumnName: "",
  });

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const [selectedTab, setSelectedTab] = useState("");

  const currentUserEmail: string | undefined = useAppSelector(
    (state: { currentUser: { data: { email: any } } }) => {
      return state.currentUser.data.email;
    }
  );

  const filterDropdown = useAppSelector(
    (state: { dashBoardDropdown: { data: any } }) => {
      return state.dashBoardDropdown.data;
    }
  );

  const cleanFilterStates = () => {
    setValueDb({});
    setFilterModel({
      items: [],
    });
    setPaginationModel({
      page: 0,
      pageSize: pageSize,
    });
  }

  useEffect(() => {

    if (props.isActive != undefined && props.isActive != "") {
      setPaginationModel({
        page: 0,
        pageSize: pageSize,
      });
      isActive.Value = props.isActive;
      localStorage.setItem("invType", JSON.stringify({ isActive: props.isActive }));
      if (props.selectedTab.length !== 0) {
        fetchData(paginationModel, { items: [] }, true);
      }
      setValueDb({});
    }

  }, [props.isActive]);

  useEffect(() => {
    var storedfilterItems: any = [];
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");
    var storedPage = localStorageDashboardPayload.paginationSet?.page;
    var storedPageSize = localStorageDashboardPayload.paginationSet?.pageSize;


    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      setValueDb(localStorageDashboardPayload.filterItems);
      setPageSize((storedPageSize !== undefined && storedPageSize !== null) ? storedPageSize : 10);
      setPaginationModel({
        page: (storedPage !== undefined && storedPage !== null) ? storedPage : 0,
        pageSize: (storedPageSize !== undefined && storedPageSize !== null) ? storedPageSize : 10,
      });
      storedfilterItems = localStorageDashboardPayload.filterItems
    } else {
      cleanFilterStates();
    }

    if (selectedTab !== props.selectedTab) {
      setSelectedTab(props.selectedTab)
      fetchData({
        page: (storedPage !== undefined && storedPage !== null) ? storedPage : 0,
        pageSize: (storedPageSize !== undefined && storedPageSize !== null) ? storedPageSize : 10,
      }, { items: storedfilterItems }, false);
    }
  }, [props.selectedTab]);

  const redirectToPage = (
    invId: string,
    authName: string,
    authId: string,
    authCode: string,
    fundName: string,
    projectName: string
  ) => {
    dispatch(
      authData({
        invID: "",
        authName: "",
        authCode: "",
        fundName: "",
        authId: "",
        projectName: "",
      })
    );
  };

  const handleCellClick = (params: any) => {

    dispatch(resetSubmitFormErrorStateAction());
    dispatch(resetBudgetedDataStateAction());
    dispatch(resetNonBudgetedDataStateAction());
    dispatch(resetAuthorizationDataStateAction());
    dispatch(resetprojectPropertyDetailDataStateAction());
    dispatch(resetAuthDataStateAction());
    dispatch(resetProposalDataStateAction());
    dispatch(resetleaseProjectDetailStateAction());
    dispatch(resetJVReasonForAuthStateAction());
    dispatch(resetModprojectDetailDataStateAction());
    dispatch(resetFormDataStateAction());

    redirectToPage(
      params.row.projectName,
      params.row.invID,
      params.row.authCode,
      params.row.authName,
      params.row.fundName,
      params.row.authId
    );

    if (params.field === "projectName") {
      let res = params.row.authName?.replace(/ /g, "");
      res = res?.replace("/", "")

      dispatch(authData({ invID: params.row.invID, authType: params.row.authName, authName: params.row.authName }));
      nav(`/authorizations/form/${params.row.authFormID}`)
    }
  };

  const handelPaginationModel = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (Object.keys(localStorageDashboardPayload).includes("filterItems")) {
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, selectedTab: props.selectedTab, paginationSet: paginationModel }));
      fetchData(paginationModel, { items: localStorageDashboardPayload.filterItems }, false);
    } else {
      localStorage.setItem(localStoragePayload, JSON.stringify({ filterItems: { projectName: "" }, selectedTab: props.selectedTab, paginationSet: paginationModel }));
      fetchData(paginationModel, filterModel, false);
    }
  };

  const handelSortModelChange = (sortModel: GridSortModel) => {
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");
    if (sortModel.length == 0) {
      sortedModel.sortedColumnName = "";
      sortedModel.ascSort = false;
    } else {
      sortedModel.sortedColumnName = sortModel[0].field;
      if (sortModel[0].sort == "asc") {
        sortedModel.ascSort = true;
      } else {
        sortedModel.ascSort = false;
      }
    }

    if (Object.keys(localStorageDashboardPayload).includes("filterItems")) {
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, selectedTab: props.selectedTab }));
      fetchData(paginationModel, { items: localStorageDashboardPayload.filterItems }, false);
    } else {
      localStorage.setItem(localStoragePayload, JSON.stringify({ filterItems: { projectName: "" }, selectedTab: props.selectedTab }));
      fetchData(paginationModel, filterModel, false);
    }
  };

  const fetchData = async (
    paginationModel: GridPaginationModel,
    filterModel: GridFilterModel,
    activeAll: boolean
  ) => {
    setLoading(true);

    await GetAuthorizations(paginationModel, filterModel, false, activeAll)
      .then((data: any) => {

        const formattedRows = data?.data.map((row: any) => ({
          ...row,
          authAmount: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(row?.authAmount),
          authDate: row?.authDate !== undefined ? moment(row?.authDate).format("MMM-DD-YYYY hh:mm A") : undefined
        }))
        setRows(formattedRows);
        if (data?.dropdownValues) {
          var dropdownValues = { ...data?.dropdownValues };

          dropdownValues.authName = Object.values(data?.dropdownValues.authName);
          dropdownValues.authNameDictionary = data?.dropdownValues.authName;
          dispatch(dashboardDropdownData(dropdownValues));
          setHeadingFilters(dropdownValues);
          setAuthNameDropdowns(data?.dropdownValues.authName);
          setStoredTab(props.selectedTab);
        } else {
          setHeadingFilters(filterDropdown);
        }
        if (data?.rowCount >= 0) {
          setRowCount(data?.rowCount);
        }
      })
      .catch((error: any) => {
        errorToastMsgwithCode(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportExcel = async () => {
    setExcelDownloading(true);
    try {
      const dashboardData = await GetAuthorizations(paginationModel, { items: valueDb }, true, false);

      // Fetch the data from the presigned URL

      const response = await fetch(dashboardData.url);
      const blobData = await response.blob();
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = url;
      link.download = response.url.split("/dashboard/")[1].split("?")[0].split(".")[0].toString() + ".xls";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);


      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 0);
    } catch (error: any) {
      console.log(error);
    } finally {
      setExcelDownloading(false);
    }
  };

  const GetAuthorizations = async (
    paginationModel: GridPaginationModel,
    filterModel: GridFilterModel,
    isExcel: boolean,
    activeAll: boolean
  ) => {

    var viewName = props.selectedTab;

    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      viewName = localStorageDashboardPayload.selectedTab
    }

    var columnFilters: any | null = null;
    columnFilters = filterModel?.items;

    const dashBoardPayload: any = {
      mailId: currentUserEmail?.toLowerCase(),
      isActive: isActive.Value === "true",
      viewName: viewName,
      columnFilters: columnFilters.length === undefined ? columnFilters : null,
      pagesize: paginationModel.pageSize,
      pageNumber: paginationModel.page,
      sortedColumnName: sortedModel.sortedColumnName,
      ascSort: sortedModel.ascSort,
      isDropdownRequired: activeAll || props.selectedTab !== storedTab,
      isForExcel: isExcel
    };

    if (isExcel) {
      return await dashboardService
        .FetchDashboardAuthorizationExcel(dashBoardPayload)
        .then((data: any) => {
          return data;
        });
    }

    return await dashboardService
      .FetchDashboardAuthorization(dashBoardPayload)
      .then((data: any) => {
        return data;
      });
  };

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
  };

  const handleDeleteAuthorization = async () => {
    var url = `${baseUrl}Authorizations/deleteorrestore`;
    const deletePayload = {
      id: deleteauthorization.id,
      isDelete: true,
    };
    try {
      const res = await postApiCall(url, deletePayload);
      setRows(
        rows.filter((item: any) => item.authFormID !== deleteauthorization.id)
      );
      setPaginationModel({
        page: 0,
        pageSize: pageSize,
      });
      setRowCount(rowCount - 1);
      setToast(true);
      setToastMessage({
        message: "Authorization deleted sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      handleCloseDeletePopup();
    } catch (error) {
      errorToastMsgwithCode(error)
    }
  };

  const handleRestore = async () => {
    var url = `${baseUrl}Authorizations/deleteorrestore`;
    const restorePayload = {
      id: restoreauthorization.id,
      isDelete: false,
    };
    try {
      const res = await postApiCall(url, restorePayload);
      setRows(
        rows.filter((item: any) => item.authFormID !== restoreauthorization.id)
      );
      setPaginationModel({
        page: 0,
        pageSize: pageSize,
      });
      setRowCount(rowCount - 1);

      setLoading(false);
      setToast(true);
      setToastMessage({
        message: "Authorization restored sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      handleCloseRestorePopup();
    } catch (error) {
      errorToastMsgwithCode(error)
    }
  };

  const sendDashboardAuthRequest = () => {

    filterModel.items = valueDb;
    paginationModel.page = 0;

    setFilterModel(filterModel);
    fetchData(paginationModel, filterModel, false);
  };

  const ref = useRef(sendDashboardAuthRequest);

  useEffect(() => {
    ref.current = sendDashboardAuthRequest;
  }, [valueDb]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);

  const handleDropdownFilterChange = (field: any, value: any) => {
    var authNameSelectedKeys: string[] = [];
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");
    if (field.toString() === "authName") {
      value.forEach((element: any) => {
        var filteredKey = Object.keys(authNameDropdowns).filter(key => authNameDropdowns[key] === element);
        authNameSelectedKeys.push(filteredKey[0]);
      });
      setValueDb({ ...valueDb, "authCode": authNameSelectedKeys });
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, filterItems: { ...valueDb, "authCode": authNameSelectedKeys }, selectedTab: props.selectedTab, paginationSet: paginationModel }));

    } else if (field.toString() === "authDate") {
      setValueDb({ ...valueDb, [field]: value.map((item: any) => moment(item).format("YYYY-MM-DDTHH:mm:ss")) });
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, filterItems: { ...valueDb, [field]: value.map((item: any) => moment(item).format("YYYY-MM-DDTHH:mm:ss")) }, selectedTab: props.selectedTab, paginationSet: paginationModel }));

    } else {
      setValueDb({ ...valueDb, [field]: value });
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, filterItems: { ...valueDb, [field]: value }, selectedTab: props.selectedTab, paginationSet: paginationModel }));
    }
    debouncedCallback();
  }
  const CustomDropDownHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    var columnFilterList = headingFilters[params.item.field] || [];
    var storedfilterItems: any = [];
    var filterValue = [];

    if (params.item.field === "authDate") {
      columnFilterList = columnFilterList.map((item: any) => moment(item).format("MMM-DD-YYYY"));
    }
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      storedfilterItems = localStorageDashboardPayload.filterItems
      if (Object.keys(storedfilterItems).includes("authCode") && colDef.field == "authName") {
        if (Object.keys(authNameDropdowns).length !== 0) {
          localStorageDashboardPayload.filterItems["authCode"].forEach((element: any) => {
            var filteredKey: any = Object.values(authNameDropdowns).filter((key: any) => authNameDropdowns[key] === element);
            filterValue.push(authNameDropdowns[element]);
          });
        }
      } else if (Object.keys(storedfilterItems).includes("authDate") && colDef.field == "authDate") {
        filterValue = localStorageDashboardPayload.filterItems[colDef.field].map((item: any) => moment(item).format("MMM-DD-YYYY"));
      } else if (Object.keys(storedfilterItems).includes(colDef.field)) {
        filterValue = localStorageDashboardPayload.filterItems[colDef.field];
      }
    }

    return (
      <Autocomplete
        className="autocomplete-root"
        ListboxProps={{
          className: "autocomplete-dashboard"
        }}
        multiple
        key={props.selectedTab || props.isActive}
        limitTags={1}
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        id="tags-standard"

        onChange={(event, newInputValue) => {
          handleDropdownFilterChange(params.item.field, newInputValue);
        }}
        value={filterValue}
        options={columnFilterList}
        disableCloseOnSelect
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}

          </li>
        )}
        fullWidth
        renderInput={(params) => <TextField  {...params} variant="standard" />}
      />
    );
  };

  const CustomTextHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    var storedfilterItems: any = [];
    var filterValue = "";

    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      storedfilterItems = localStorageDashboardPayload.filterItems

      if (Object.keys(localStorageDashboardPayload.filterItems).includes(params.colDef.field)) {
        filterValue = localStorageDashboardPayload.filterItems[params.colDef.field];
      }
    }


    return (
      <TextField
        id="standard-basic"
        variant="standard"
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        key={props.selectedTab || props.isActive}
        onChange={(event) => {
          setValueDb({ ...valueDb, [params.item.field]: event.target.value });
          debouncedCallback();
          localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, filterItems: { ...valueDb, [params.item.field]: event.target.value }, selectedTab: props.selectedTab }));
        }}
        value={filterValue}
        InputProps={{
          ref: inputRef
        }}
      />
    );
  };

  const columns: any = dashboardViewsColumnConfig.colDef.map((colDef: any) => {

    let { valueFormatter, ...rest } = colDef;
    if (
      colDef.field === "fundName" ||
      colDef.field === "invID" ||
      colDef.field === "authName" ||
      colDef.field === "authDate" ||
      colDef.field === "status"
    ) {
      return {
        ...rest,
        renderHeaderFilter: CustomDropDownHeaderFilter,
      };
    } else {
      if (colDef.field === "projectName") {
        return {
          ...rest,
          renderHeaderFilter: CustomTextHeaderFilter,
          cellClassName: (params: GridCellParams<any, number>) => {
            if (!params.value) {
              return "project-name-blank";
            }

            return "project-name-cell";
          },
        };
      }
      if (colDef.field === "authAmount") {
        return {
          ...rest,
          renderHeaderFilter: () => null
        }
      }
      return {
        ...rest,
        renderHeaderFilter: CustomTextHeaderFilter,
      };
    }
  });

  if (userdata.isAdmin && storedTab !== "HISTORICAL AUTHORIZATIONS") {
    columns.unshift({
      headerName: "Actions",
      field: "Actions",
      type: "actions",
      getActions: ({ ...rest }) => {    
        const shouldShowDeleteIcon = true;
        if (storedTab !== "DELETED AUTHORIZATIONS") {
          return shouldShowDeleteIcon ? [
            <>
              <GridActionsCellItem
                sx={{
                  color: "#253746",
                }}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={(e) => handleClickOpenDeletePopup(rest.row)}
              />
            </>,
          ] : [];
        } else {
          return [
            <>
              <GridActionsCellItem
                sx={{
                  color: "#253746",
                }}
                icon={<RestoreFromTrashIcon />}
                label="Restore"
                onClick={(e) => handleClickOpenRestorePopup(rest.row)}
              />
            </>,
          ];
        }
      },
    });
  }

  return (
    <>

      <Box className="dashboard-table-wrapper">
        <Grid item xs={12} sm={6} md={1} className="master-excel-grid">
          <LoadingButton
            variant="contained"
            className="excel-btn-reports"
            loading={excelDownloading}
            onClick={exportExcel}
          ><FileDownloadOutlinedIcon sx={{ mr: "0.5px" }} />EXCEL</LoadingButton>
        </Grid>
        <div style={{ width: "100%" }}>
          <DataGridPro
            className="dashboard-dataGrid"
            slots={{
              loadingOverlay: GridLoadingOverlay,
            }}
            sx={{
              pointerEvents: loading ? 'none' : 'auto',
              opacity: loading ? 0.7 : 1,
            }}
            getRowId={(row) => row.authFormID}
            rows={rows ?? []}
            rowCount={rowCount}
            columns={columns}
            headerFilters
            columnHeaderHeight={48}
            headerFilterHeight={48}
            pageSizeOptions={[5, 10, 100]}
            rowHeight={30}
            paginationMode="server"
            // filterMode="server"
            sortingMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handelPaginationModel}
            loading={loading}
            onSortModelChange={(model: GridSortModel) =>
              handelSortModelChange(model)
            }
            disableRowSelectionOnClick
            onCellClick={handleCellClick}
            pagination
            disableColumnMenu={true}
            autoHeight
            hideFooter={false}
          />
        </div>
        <Dialog
          open={openDeletePopup}
          onClose={handleCloseDeletePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="common-dialog-box"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to delete the selected authorization?"}
          </DialogTitle>
          <DialogActions>
            <Button
              className="primary-outline"
              onClick={handleCloseDeletePopup}
            >
              No
            </Button>
            <Button
              className="primary-outline"
              onClick={handleDeleteAuthorization}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openRestorePopup}
          onClose={handleCloseRestorePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="common-dialog-box"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to restore the selected authorization?"}
          </DialogTitle>
          <DialogActions>
            <Button
              className="primary-outline"
              onClick={handleCloseRestorePopup}
            >
              No
            </Button>
            <Button className="primary-outline" onClick={handleRestore}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </>
  );
};

export default DashboardDataGrid;
