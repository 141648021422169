import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';

const AuthorizationHistory : React.FC<any>= ({ formData }) => {
  const tableCellStyle = {
    backgroundColor: "grey",
    color: "#fff",
  };
  const tableCellRows = {
    backgroundColor: "lightgrey",
    color: "#fff",
  };

  return (
    <TableContainer>
    <Table>
      <TableHead>
        <TableRow style={tableCellStyle}>
          <TableCell>Role</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Level</TableCell>
          <TableCell>Date&Time</TableCell>
          <TableCell>Comments</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {formData?.approvalsInfo?.map((item: any, index: any) =>
        (
          <TableRow key={index} style={tableCellRows}>

            <TableCell>{item.role}</TableCell>
            <TableCell>{item.userName}</TableCell>
            <TableCell>{item.level}</TableCell>
            <TableCell>{moment(item.createdOn).format("MMM-DD-YYYY hh:mm A")}</TableCell>
            <TableCell>{item.comments}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>

  );
};

export default AuthorizationHistory;
