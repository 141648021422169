import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getApiCall } from "../../services/ApiHelper";
import { baseUrl } from "../../components/config/Config";

export interface UserData {
    email: string | null;
    isExternal: boolean;
    isPropertyManager: boolean;
    isAssetManager: boolean;
    isAccounting: boolean;
    isAdmin: boolean;
}

interface UserState {
    data: UserData;
    loading: boolean;
    error: string | null;
}

const initialState: UserState = {
    data: {
        email: null,
        isExternal: false,
        isPropertyManager: false,
        isAssetManager: false,
        isAccounting: false,
        isAdmin: false
    },
    loading: false,
    error: null,
};


export const getUserData = createAsyncThunk("getUserData", async (payload: string, {rejectWithValue}) => {
    try {
        var url = `${baseUrl}Users/UserContextDetails?mailId=${payload}`;
        const response = await getApiCall<UserData>(url);
        return response;
    } catch (error: any) {
        console.error('Error in GET request:', error);
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const userContextDetailSlice = createSlice({
    name: 'userContextDetail',
    initialState,
    reducers: {
        // Define your other reducers here
    },
    extraReducers: (builder) => {
        builder.addCase(getUserData.pending, (state) => {
            state.loading = true;
        })
        .addCase(getUserData.fulfilled, (state, action: PayloadAction<UserData>) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(getUserData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'something went wrong';
        })
    },
});

export const getuserdetailsdata= (state:any) => state.userContextDetail.data

export default userContextDetailSlice.reducer;
