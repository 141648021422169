import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ViewTabs = (props: any) => {
    const initialTabIndex = 0;
    const [value, setValue] = useState(initialTabIndex);

    const handleChange = (event: any, newValue: any) => {
        const viewId = event.target.id;
        props.sendData(viewId);
        setValue(newValue);
        localStorage.setItem("dashBoardPayload", JSON.stringify({}));
    };

    React.useEffect(() => {
        const localStorageDashboardPayload = JSON.parse(localStorage.getItem("dashBoardPayload") || "[]");

        if (props.tabData !== undefined && props.tabData !== null) {
            if (Object.keys(localStorageDashboardPayload).length !== 0) {
                props.sendData(localStorageDashboardPayload.selectedTab.length === 0 ? props.tabData[0] : localStorageDashboardPayload.selectedTab);
                if (props.tabData !== null && props.tabData !== undefined) {
                    setValue(props.tabData.findIndex((tab: any) => tab === localStorageDashboardPayload.selectedTab) >= 0 ? props.tabData.findIndex((tab: any) => tab === localStorageDashboardPayload.selectedTab) : 0);
                }
            } else {
                props.sendData(props.tabData[0]);
            }
        }
    }, [props.tabData]);

    return (
        <>
            <Tabs
                className="tabs-dashbard"
                sx={{
                    ".Mui-selected:hover": {
                        color: `white`,
                    },
                }}
                value={value}
                onChange={handleChange}
                aria-label=""
            >
                {props.tabData?.map((tabTitle: any, index: any) => (
                    <Tab label={tabTitle} id={tabTitle} key={index} />
                ))}
            </Tabs>
        </>
    );
};

export default ViewTabs;
