import * as React from 'react';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import ViewTabs from '../components/dashboard/ViewTabs';
import DashboardDataGrid from '../components/dashboard/dashboardDataGrid';
import { getTabs } from '../redux/slices/dashboardTabsSlice';
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { getInvdetailsdata, getinvdetails } from '../redux/slices/createAuthSlice';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, Grid, TextareaAutosize } from "@mui/material";

import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { theme } from "../components/config/Config";
import { ThemeProvider } from "@mui/material/styles";


const Dashboard = () => {

  const dispatch = useAppDispatch();
  const currentUserEmail: string | undefined = useAppSelector((state) => { return state.currentUser.data.email });
  const tabs: string[] | null = useAppSelector((state) => { return state.dashboardTab.tabs });
  const [open, setOpen] = useState<boolean>(false);
  const [selectedViewTab, setSelectedViewTab] = useState<string>("")
  const [pageSize, setPageSize] = useState<number>(10);
  const [isActive, setIsActive] = useState<any>(JSON.parse(localStorage.getItem("invType") || "[]").isActive === "false" ? "false" : "true");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const activestate = value;
    setIsActive(activestate);
    localStorage.removeItem("dashBoardPayload");
  };

  useEffect(() => {
    dispatch(getTabs(currentUserEmail));
  }, [currentUserEmail])

  const tabChange = (id: string) => {
    setSelectedViewTab(id)
  }


  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} className='grid-header-dashboard'>
          <Grid item xs={8}>
            <h2 className="text-left title" > Authorizations Dashboard</h2>
          </Grid>
          {/* <Grid item sm={4}></Grid> */}
          <Grid item xs={4}>
            <div className='activeall'>
              <FormControl>
                <RadioGroup row className="new-auth-btn"
                  onChange={handleRadioChange}
                  value={isActive}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Active" />
                  <FormControlLabel value="false" control={<Radio />} label="All" />

                </RadioGroup>

              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div className="clear"></div>
        <div className="wrapper">
          <ViewTabs sendData={tabChange} tabData={tabs} />
          <DashboardDataGrid selectedTab={selectedViewTab} isActive={isActive} />
        </div>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default Dashboard;