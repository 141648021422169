import React from 'react';
import { Grid, Autocomplete, TextField } from '@mui/material';
import { updateAuthorizationData } from '../../../redux/slices/authorizationDataSlice';
import { updateErrorFields } from '../../../redux/slices/submitFormErrorSlice';

const AssetManagerField : React.FC<any>= ({ formData, selectedAssetManager, setSelectedAssetManager, dispatch, submitErrorModel, authorizationData }) => {
  return (
    <Grid item xs={12} md={6}>
      <Grid>
        <label htmlFor="field2">Recommended By</label> <span className="mandatory"> * </span>
      </Grid>
      <Grid>
        <Autocomplete
          fullWidth
          size="small"
          disablePortal
          disabled={!formData?.isFormEditable}
          data-testid="property-select asset"
          value={selectedAssetManager}
          options={formData?.assetManager ?? []}
          getOptionLabel={(option: any) => option?.name}
          isOptionEqualToValue={(option: any, value: any) => option?.name === value?.name}
          onChange={(event, newInputValue: any) => {
            setSelectedAssetManager(newInputValue ? newInputValue : { name: "", email: "" });
            dispatch(updateErrorFields({
              ...submitErrorModel.data,
              assetManager: false
            }));
            dispatch(updateAuthorizationData({
              ...authorizationData,
              recommendedBy: newInputValue ? newInputValue?.name : "",
              recommendedByEmail: newInputValue ? newInputValue?.email : ""
            }));
          }}
          renderInput={(params) => <TextField {...params} error={submitErrorModel.data.assetManager} />}
        />
      </Grid>
    </Grid>
  );
};

export default AssetManagerField;
