import AccordionTab from "../utils/AccordianTab";
import {
  Autocomplete,
  Container,
  Grid,
  Input,
  InputLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import * as React from "react";
import { updateproposalData } from "../../redux/slices/proposalSlice";
import { update } from "lodash";
import { useEffect } from "react";
import { Description } from "@mui/icons-material";
import { updateErrorFields } from "../../redux/slices/submitFormErrorSlice";
import { updateAuthorizationData } from "../../redux/slices/authorizationDataSlice";


const Proposal: React.FC<Record<any, any>> = () => {
  const formData = useAppSelector((state) => state.getFormData.formData);
  const submitErrorModel = useAppSelector((state) => state.submitFormError);
  const authorizationData = useAppSelector((state) => { return state.authorizationData.data });


  const proposaldata = useAppSelector((state) => state.proposaldata.data);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (formData !== null) {

      dispatch(
        updateproposalData({
          TerminationFee: formData?.terminationFee,

          TerminationTime: formData?.terminationTime,

          projectName: formData?.projectName,
          projectDescription: formData?.description,
          Leasingcompany: formData?.leasingCompany,
          PropertyMgmtCompany: formData?.propertyMgmtCompany,
          ExpansionFee: formData?.expansionFee,

          ExtensionFee: formData?.extensionFee,

          ManagementFee: formData?.managementFee,

          OtherFee: formData?.otherFee,
        })
      );
    }
  }, [formData]);

  function usePrevious(value: any) {
    const ref = React.useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

var prevAmount: any = usePrevious(proposaldata.TerminationFee);
var prevLeaseTerms: any = usePrevious(proposaldata.TerminationTime);
useEffect(() => {
        var leaseAmountPrevCheck = (prevAmount !== undefined && prevAmount !== null );
        var leaseTermsPrevCheck = (prevLeaseTerms !== undefined && prevLeaseTerms !== null );

        if (leaseAmountPrevCheck || leaseTermsPrevCheck ) {
            dispatch(updateAuthorizationData({ ...authorizationData, selectedApprovers: [] }));
        }

    }, [proposaldata.TerminationFee,proposaldata.TerminationTime ]);

  const handleTerminationfee = (event: any, newInputValue: any) => {
    dispatch(
      updateproposalData({
        ...proposaldata,
        TerminationFee: newInputValue,
      })
    );
    dispatch(
      updateErrorFields({
        ...submitErrorModel.data,
        appointmentterminationfee: false,
      })
    );
  }

  const handleTerminationtime = (event: any, newInputValue: any) => {
    dispatch(
      updateproposalData({
        ...proposaldata,
        TerminationTime: newInputValue,
      })
    );
    dispatch(
      updateErrorFields({
        ...submitErrorModel.data,
        appointemnetterminationtime: false,
      })
    );
  }

  return (
    <AccordionTab
      title={"Proposal"}
      children={
        <Grid container spacing={3}>
          <Grid item xs={6} md={6}>
            <Grid>
              <label>Project Name</label>
              <span className="mandatory"> *</span>

            </Grid>
            <Grid>
              <TextField
                size="small"
                fullWidth
                value={proposaldata?.projectName}
                disabled={!formData?.isFormEditable}
                inputProps={{
                  min: 0,
                  maxLength: 256,
                  onPaste: (e) => {
                    const pastedText = e.clipboardData.getData('text');
                    if (pastedText.length > 256) {
                      e.preventDefault();
                    }
                  }
                }}
                error={submitErrorModel.data.appprojectname}
                onChange={(event) => {
                  dispatch(
                    updateproposalData({
                      ...proposaldata,
                      projectName: event.target.value,
                    })
                  );
                  dispatch(
                    updateErrorFields({
                      ...submitErrorModel.data,
                      appprojectname: false,
                    })
                  );
                }}

              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid>
              <label>Detailed Project Description</label>
            </Grid>
            <Grid>
              <TextField
                multiline
                minRows={3}
                maxRows={7}
                size="small"
                fullWidth={true}
                value={proposaldata?.projectDescription}
                disabled={!formData?.isFormEditable}
                onChange={(event) => {
                  dispatch(
                    updateproposalData({
                      ...proposaldata,
                      projectDescription: event.target.value,
                    })
                  );
                }}
              ></TextField>
            </Grid>
          </Grid>
          {formData?.showLCField &&
            <Grid item xs={6} md={6}>
              <Grid>
                <label>Leasing Company</label>
              </Grid>
              <Grid>
                <TextField
                  size="small"
                  fullWidth
                  value={proposaldata?.Leasingcompany}
                  disabled={!formData?.isFormEditable}
                  inputProps={{
                    min: 0,
                    maxLength: 256,
                    onPaste: (e) => {
                      const pastedText = e.clipboardData.getData('text');
                      if (pastedText.length > 256) {
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(event) => {
                    dispatch(
                      updateproposalData({
                        ...proposaldata,
                        Leasingcompany: event.target.value,
                      })
                    );
                  }}
                ></TextField>
              </Grid>
            </Grid>}
          {formData?.showPMCField &&

            <Grid item xs={6} md={6} >
              <Grid>
                <label>Property Management Company</label>
              </Grid>
              <Grid>
                <TextField
                  size="small"
                  fullWidth
                  value={proposaldata?.PropertyMgmtCompany}
                  disabled={!formData?.isFormEditable}
                  inputProps={{
                    min: 0,
                    maxLength: 256,
                    onPaste: (e) => {
                      const pastedText = e.clipboardData.getData('text');
                      if (pastedText.length > 256) {
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(event) => {
                    dispatch(
                      updateproposalData({
                        ...proposaldata,
                        PropertyMgmtCompany: event.target.value,
                      })
                    );
                  }}
                ></TextField>
              </Grid>
            </Grid>}
          {(!formData?.showLCField || !formData?.showPMCField) && <Grid item xs={6} md={6} />}
          <Grid item xs={6} md={6}>
            <Grid>
              <label>Termination Fee </label>{" "}
              <span className="mandatory"> *</span>
            </Grid>
            <Grid>
              <Autocomplete
                value={proposaldata?.TerminationFee ?? []}
                disabled={!formData?.isFormEditable}
                size="small"
                fullWidth
                disablePortal
                data-testid="property-select approval"
                options={
                  formData?.terminationFeeList || []
                }
                onChange={handleTerminationfee}
                renderInput={(params) => <TextField {...params}
                  error={submitErrorModel.data.appointmentterminationfee}
                ></TextField>}

              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Grid>
              <label>Termination Time </label>{" "}
              <span className="mandatory"> *</span>
            </Grid>
            <Grid>
              <Autocomplete
                size="small"
                fullWidth
                disablePortal
                data-testid="property-select approval"
                options={
                  formData?.terminationTimeList || []
                }
                value={proposaldata?.TerminationTime ?? []}
                disabled={!formData?.isFormEditable}
                onChange={handleTerminationtime}
                renderInput={(params) => <TextField {...params}
                  error={submitErrorModel.data.appointemnetterminationtime} ></TextField>}
              />
            </Grid>
          </Grid>

          {formData?.showExpansionFee ?
            <Grid item xs={12}>
              <Grid>
                <label>New Lease/Expansion Fee</label>
              </Grid>
              <Grid>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={7}
                  size="small"
                  value={proposaldata?.ExpansionFee}
                  disabled={!formData?.isFormEditable}
                  fullWidth={true}
                  onChange={(event) => {
                    dispatch(
                      updateproposalData({
                        ...proposaldata,
                        ExpansionFee: event.target.value,
                      })
                    );
                  }}
                ></TextField>
              </Grid>
            </Grid> : []}
          {formData?.showExtensionFee ?
            <Grid item xs={12} >
              <Grid>
                <label>Renewal/Extension Fee</label>
              </Grid>
              <Grid>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={7}
                  size="small"
                  value={proposaldata?.ExtensionFee}
                  disabled={!formData?.isFormEditable}
                  fullWidth={true}
                  onChange={(event) => {
                    dispatch(
                      updateproposalData({
                        ...proposaldata,
                        ExtensionFee: event.target.value,
                      })
                    );
                  }}
                ></TextField>
              </Grid>
            </Grid> : []}
          {formData?.showMgmtFee ?
            <Grid item xs={12} >
              <Grid>
                <label>Management Fee</label>
              </Grid>
              <Grid>
                <TextField
                  size="small"
                  multiline
                  minRows={3}
                  maxRows={7}
                  value={proposaldata?.ManagementFee}
                  disabled={!formData?.isFormEditable}
                  fullWidth={true}
                  onChange={(event) => {
                    dispatch(
                      updateproposalData({
                        ...proposaldata,
                        ManagementFee: event.target.value,
                      })
                    );
                  }}
                ></TextField>
              </Grid>
            </Grid> : []}
          {formData?.showOtherFee ?
            <Grid item xs={12} >
              <Grid>
                <label>Other Fee</label>
              </Grid>
              <Grid>
                <TextField
                  fullWidth={true}
                  multiline
                  minRows={3}
                  maxRows={7}
                  value={proposaldata?.OtherFee}
                  disabled={!formData?.isFormEditable}
                  onChange={(event) => {
                    dispatch(
                      updateproposalData({
                        ...proposaldata,
                        OtherFee: event.target.value,
                      })
                    );
                  }}
                ></TextField>
              </Grid>
            </Grid> : []}
        </Grid>
      }
    />
  );
};
export default Proposal;
