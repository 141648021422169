// slices/errorSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { isRejected } from '@reduxjs/toolkit';

interface ErrorState {
  globalErrors: {
    [key: string]: {
      message: string;
      timestamp: number;
      sliceName?: string;
    }
  }
}

const initialState: ErrorState = {
  globalErrors: {}
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    clearError: (state, action) => {
      delete state.globalErrors[action.payload];
    },
    clearAllErrors: (state) => {
      state.globalErrors = {};
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isRejected,
      (state, action) => {
        // Extract slice name and action name from the action type
        const [sliceName, actionName] = action.type.split('/');
        state.globalErrors[action.type] = {
          message: String(action.payload) || 'An error occurred',
          timestamp: Date.now(),
          sliceName: sliceName
        };
      }
    );
  }
});

export const { clearError, clearAllErrors } = errorSlice.actions;
export default errorSlice.reducer;
