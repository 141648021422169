import React from 'react';
import { Grid, FormControl, Select, MenuItem } from '@mui/material';
import { updateAuthorizationData } from '../../../redux/slices/authorizationDataSlice';

const ValuationField: React.FC<any> = ({ formData, valuationSelect, handleValuationChange, dispatch, submitErrorModel, authorizationData }) => {
  return (
    <Grid item xs={12} md={8}>
      <Grid>
        <label>Is requested auth greater than 20% of the current inv valuation?</label><span className="mandatory"> *</span>
      </Grid>
      <Grid>
        <FormControl sx={{ m: 1, minWidth: 120, margin: 0, width: "15%" }} size="small">
          <Select
            displayEmpty
            renderValue={(selected) => selected === '' ? <span>Select</span> : selected}
            data-testid="valuation-select"
            value={valuationSelect}
            inputProps={{ 'aria-label': 'Without label' }}
            disabled={!formData?.isFormEditable && !formData?.showApprovers}
            onChange={(event) => {
              handleValuationChange(event.target.value as string);
              dispatch(updateAuthorizationData({
                ...authorizationData,
                isAmtHigherThanValuationPercentage: event.target.value as string === "Yes",
                selectedApprovers: []
              }));
            }}
          >
            <MenuItem value={"Yes"}>Yes</MenuItem>
            <MenuItem value={"No"}>No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ValuationField;
