import Dropdown from './dropDown';
import { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from 'react-router-dom';

function useMyCustomHook<T extends HTMLElement>() {
    const myRef = useRef<T>(null)

    return { ref: myRef }
}

const MenuItems = (props: any) => {
    const [dropdown, setDropdown] = useState(false);
    const { ref: myElementRef } = useMyCustomHook<HTMLLIElement>()
    useEffect(() => {
        const handler = (event: any) => {
            if (dropdown && myElementRef.current && !myElementRef.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        setDropdown(true);
    };

    const onMouseLeave = () => {
        setDropdown(false);
    };

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };
    const path = useLocation();

    const highlightedCss = !path.pathname.startsWith("/authorizations/dashboard") && path.pathname.toLowerCase().startsWith(`/authorizations/${props.items.title.toLowerCase()}`)
        ? "dropbtn-selected dropbtn"
        : "dropbtn";
    return (
        <li className="menu-items" ref={myElementRef}
            onClick={closeDropdown}>
            {props.items.submenu ? (
                <div className='menu-items-txt' >
                    <button
                        className={highlightedCss}
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}
                    // onClick={() => setDropdown(() => !dropdown)}
                    >
                        {props.items.title}{' '}
                    </button>
                    <div className="dropdown">
                        <Dropdown submenus={props.items.submenu} dropdown={dropdown} hoverEnd={onMouseLeave} hoverStart={onMouseEnter} />
                    </div>
                </div>
            ) : (
                <NavLink style={{ fontSize: "16px", fontWeight: 450 }} to={props.items.url}>{props.items.title}</NavLink>
            )}
        </li>
    );
};

export default MenuItems;