import React, { useEffect } from 'react';
import { useAppState } from './authorizationSectionHelper/useStateHooks';

const LeaseAuthSection = (props:any) => {
    const appState = useAppState();
    function usePrevious(value: any) {
        const ref = React.useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    var prevMarketRate: any = usePrevious(props?.localState.authApproversRequest.marketRate);
    var prevAmount: any = usePrevious(props?.localState.authApproversRequest.amount);
    var prevLeaseTerms: any = usePrevious(props?.localState.authApproversRequest.term);
    var prevLeasePremises: any = usePrevious(props?.localState.authApproversRequest.sqft);

    useEffect(() => {
        if (props?.constants.isAssetManagerWithEditAccess) {
            props.localState.authApproversRequest.fundName = appState.formData?.fundName;
            props.localState.authApproversRequest.authType = appState.formData?.authCode;
            props.localState.authApproversRequest.term = !props?.constants.leaseTermsIsNotEmpty ? 0 : appState.leaseProjectDetails.leaseTerms;
            props.localState.authApproversRequest.sqft = !props?.constants.leasePremiseIsNotEmpty ? 0 : appState.leaseProjectDetails.leasePremise;
            props.localState.authApproversRequest.marketRate = appState.leaseProjectDetails.leaseMarketRate === "yes" ? true : (appState.leaseProjectDetails.leaseMarketRate === "no" ? false : null);
            props.localState.authApproversRequest.terminationFee = ""
            props.localState.authApproversRequest.terminationTime = ""
            if (appState.formData?.showTiaaOwnershipAmt) {
                props.localState.authApproversRequest.amount = (appState.leaseProjectDetails?.amount * (appState.formData?.tiaaOwnershipShare / 100));
            } else {
                props.localState.authApproversRequest.amount = appState.leaseProjectDetails.amount;
            }

            if (props?.constants.leaseTermsIsNotEmpty || props?.constants.leasePremiseIsNotEmpty || props?.constants.leaseMarketRateIsNotNull || props?.constants.leaseAmountNotNull) {
                if (!(props?.localState.authApproversRequest.marketRate === prevMarketRate && props?.localState.authApproversRequest.amount === prevAmount &&
                    props?.localState.authApproversRequest.term === prevLeaseTerms && props?.localState.authApproversRequest.sqft === prevLeasePremises)) {
                        props?.fetchApprovers();
                        props?.localState.setShowApprovers(true);
                }
            }
            else {
                props?.localState.setShowApprovers(false);
            }
        }
    }, [appState.leaseData.leasePremises, appState.leaseData.leaseTerms, appState.leaseData.leaseamount, appState.leaseProjectDetails.leaseMarketRate]);

    return null;
};

export default LeaseAuthSection;
