import React, { useEffect, useState } from "react";
import AccordionTab from "../utils/AccordianTab";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  FormControlLabel,
  Container,
  Grid,
  SelectChangeEvent,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getFormData } from "../../redux/slices/getFormDataSlice";
import { PropertyProjectDetailState } from "../../interfaces/Interfaces";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { makeStyles } from "tss-react/mui";
import { updateErrorFields } from "../../redux/slices/submitFormErrorSlice";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/en'

const useStyles = makeStyles()((theme) => ({
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  },
}));

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        allowNegative={false}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$ "
      />
    );
  },
);

const ProjectDetails: React.FC<Record<any, any>> = (props) => {
  const formData = useAppSelector(getFormData);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const submitErrorModel = useAppSelector((state) => state.submitFormError);

  const [disableTextBox, setDisableTextBox] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState<any>("");
  const [projectManagementFees, setProjectManagementFees] = useState<number>(0);
  const [authorizationAmount, setauthorizationAmount] = useState<number>(0);
  const [propertyDetailDescription, setpropertyDetailDescription] = useState("");
  const [dateOfCompletion, setDateOfCompletion] = useState<any>(null);

  const [propertyProjectdetailError, setPropertyProjectdetailError] = useState({
    AuthAmount:
      props.errorObj?.length > 0 ? props.errorObj[0].AuthAmount : false,
    projectMgmtFee:
      props.errorObj?.length > 0 ? props.errorObj[0].projectMgmtFee : false,
  });

  function handleParentCallback(Id: number, key: string, value: any) {
    props.sendDataToParent(Id, key, value);
  }

  const handleDelete = (sectionNumber: number) => {
    handleParentCallback(sectionNumber, "isDeleted", true);
  }

  useEffect(() => {
    setSelectedProperty(props?.propertyDetails?.name);
    setDisableTextBox(props?.propertyDetails?.projectManagementFeeNA);
    setProjectManagementFees(props?.propertyDetails?.projectManagementFee);
    setauthorizationAmount(props?.propertyDetails?.authorizationAmount);
    setpropertyDetailDescription(props?.propertyDetails?.description);
    setDateOfCompletion(typeof (props?.propertyDetails?.dateOfCompletion) === "string" ? props?.propertyDetails?.dateOfCompletion?.split("T")[0] : null);
    
  }, []);

  useEffect(() => {
    setPropertyProjectdetailError({
      projectMgmtFee:
        props.errorObj?.length > 0 ? props.errorObj[0].projectMgmtFee : false,
      AuthAmount:
        props.errorObj?.length > 0 ? props.errorObj[0].AuthAmount : false,
    });
  }, [submitErrorModel.data.propertyProjectDetail]);

  const handleProjectManagementFee = (event: any) => {
    setProjectManagementFees(parseFloat(event.target.value));

    var filteredErrorObject: Array<{ id: number; projectMgmtFee: boolean; AuthAmount: boolean; }> = submitErrorModel?.data?.propertyProjectDetail.filter((element: any) => element.id !== props.sectionNumber);

    dispatch(updateErrorFields({ ...submitErrorModel.data, propertyProjectDetail: filteredErrorObject }));
    setPropertyProjectdetailError({ projectMgmtFee: false, AuthAmount: false, });

    if (!isNaN(parseFloat(event.target.value))) {
      handleParentCallback(props.sectionNumber, "projectManagementFee", parseFloat(event.target.value));
    } else {
      handleParentCallback(props.sectionNumber, "projectManagementFee", 0);
    }
  }

  const handleAuthAmount = (event: any) => {
    setauthorizationAmount(parseFloat(event.target.value));
    dispatch(updateErrorFields({ ...submitErrorModel.data, authAmountError: false }));

    if (!isNaN(parseFloat(event.target.value))) {
      handleParentCallback(
        props.sectionNumber,
        "authorizationAmount", parseFloat(event.target.value)
      );
      setauthorizationAmount(parseFloat(event.target.value));
    } else {
      handleParentCallback(
        props.sectionNumber,
        "authorizationAmount", 0
      );
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableTextBox(event.target.checked);

    dispatch(
      updateErrorFields({
        ...submitErrorModel.data,
        projMangFee: event.target.checked,
      })
    );

    handleParentCallback(props.sectionNumber, "projectManagementFeeNA", event.target.checked);

    setProjectManagementFees(0);
  };

  const handlePaste = (event:any) => {
    const pasteData = event.clipboardData.getData("text");
    const trimmedData = pasteData.slice(0, 14);
    event.preventDefault();
    setProjectManagementFees((prev) => prev + trimmedData);
  };

  return (
    <Grid container spacing={2} className="grid-border">
      {props.isEditable ? (<Grid item xs={12}>
        <DeleteIcon sx={{ mr: "2.5px", float: "right", cursor: "pointer" }} onClick={() => { handleDelete(props.sectionNumber); }} />
      </Grid>) : null}
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Select Property</label>
        </Grid>
        <Grid>
          <Autocomplete
            disablePortal
            id="property-select"
            value={selectedProperty}
            fullWidth
            size="small"
            disabled={!formData?.isFormEditable}
            onChange={(event, newInputValue) => {
              if (newInputValue !== null) {
                setSelectedProperty(newInputValue);
                handleParentCallback(
                  props.sectionNumber,
                  "name", newInputValue
                );
              } else {
                setSelectedProperty("");
                handleParentCallback(
                  props.sectionNumber,
                  "name", ""
                );
              }

            }}
            options={formData?.properties ? formData?.properties[0]?.split(",")?.map((data: any) => data) : []}
            renderInput={(params) => <TextField
              {...params} />}
          />
        </Grid>
      </Grid>
      {formData?.showProjectMgmtFee  &&
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project Management Fee</label><span className="mandatory"> * </span>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!formData?.isFormEditable}
                  checked={disableTextBox}
                  onChange={handleCheckboxChange}
                />
              }
              label="NA"
            />
          </Grid>
          <Grid item xs={10}>
            <TextField size="small" disabled={!formData?.isFormEditable || disableTextBox}
              fullWidth
              error={propertyProjectdetailError.projectMgmtFee}
              value={projectManagementFees}
              InputProps={{
                inputComponent: NumericFormatCustom as any
              }}
              onPaste={handlePaste}
              inputProps={{ min: 0, style: { textAlign: 'right' },maxLength : 20 }}
              className={classes.classes.input}
              onChange={(event) => handleProjectManagementFee(event)}></TextField>

          </Grid>
        </Grid>

      </Grid> }
     
      <Grid item xs={12}>
        <Grid>
          {formData?.showProjectMgmtFee  ?
          <label>Description</label> :
          <label>Recommendation</label> }
        </Grid>
        <Grid>
          <TextField fullWidth size="small"
            minRows={3}
            maxRows={7}
            multiline
            value={propertyDetailDescription}
            disabled={!formData?.isFormEditable}
            onChange={(event) => {
              setpropertyDetailDescription(event.target.value)
              handleParentCallback(props.sectionNumber, "description", event.target.value);
            }}
          ></TextField>
        </Grid>
      </Grid> 
      {formData?.showProjectAuthAmt  && 
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Authorization Amount</label>
        </Grid>
        <Grid>
          <TextField size="small" disabled={!formData?.isFormEditable}
            fullWidth
            error={propertyProjectdetailError.AuthAmount}
            value={authorizationAmount}
            InputProps={{
              inputComponent: NumericFormatCustom as any
            }}
            inputProps={{ min: 0, style: { textAlign: 'right' } ,maxLength : 20 }}
            onPaste={handlePaste}
            
            className={classes.classes.input}
            onChange={(event) => handleAuthAmount(event)}></TextField>
        </Grid>
      </Grid> }
      {formData?.showProjectDate && 

      <Grid item xs={12} md={6}>
        <Grid>
          <label>Date of Completion</label>
        </Grid>
        <Grid className="datepicker-grid">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en" >
              <DatePicker
                format="MMM-DD-YYYY"
                slotProps={{ textField: { fullWidth: true, size: "small" , inputProps : {disabled : true}} }}
                disabled={!formData?.isFormEditable}
                value={dateOfCompletion?dayjs(dateOfCompletion):null}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    var setDate = new Date(newValue?.year(), newValue?.month(), newValue?.date(), 12, 0, 0);
                    handleParentCallback(props.sectionNumber, "dateOfCompletion", setDate);
                    setDateOfCompletion(setDate);
                  }
                  else {
                    handleParentCallback(props.sectionNumber, "dateOfCompletion", null);
                    setDateOfCompletion(null);
                  }
                }
                }
              />
          </LocalizationProvider>
        </Grid> 
      </Grid> }
    </Grid > 
  );
};

export default ProjectDetails;
