import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { baseUrl, theme } from "../../../components/config/Config";
import { getApiCall, postApiCall } from "../../../services/ApiHelper";
import { Button, Grid } from "@mui/material";
import CapitalProjectsDataGrid from "./CapitalProjectsDataGrid";
import CapitalProjectDialogBox from "./CapitalProjectDialogBox";
import { capProjectResponse, toast } from "../../../interfaces/Interfaces";
import { ToastMessage } from "../../../components/utils/ToastMessage";

function CapitalProjects() {
  const [rowData, setRowData] = useState<any>();
  const [adHocDropdown, setAdHocDropdown] = useState<any>();
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [invLoading, setInvLoading] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [gridDropDown, setGridDropDown] = useState<any>({
    InvId: [],
    InvName: [],
    CreatedBy: [],
    CreatedOn: [],
    UpdatedBy: [],
    UpdatedOn: [],
  });
  const [submitdeactivate, setdeactivate] = useState<boolean>(false);

  const [updateData, setUpdateData] = useState<capProjectResponse>({
    id: null,
    InvID: "",
    InvName: "",
    projectID: "",
    name: "",
    budgetYear: "",
    AmountBudgeted: "",
    AmountSpent: "",
    comments: "",
    isActive: true,
  });

  useEffect(() => {
    if (!openDialog) {
      setUpdateData({
        id: null,
        InvID: "",
        InvName: "",
        projectID: "",
        name: "",
        budgetYear: "",
        AmountBudgeted: "",
        AmountSpent: "",
        comments: "",
        isActive: true,
      });
    }
  }, [openDialog]);

  useEffect(() => {
    if (submitClicked) {
      getCapitalProjectsData();

    }
  }, [submitClicked]);
  useEffect(() => {
    if (submitdeactivate) {
      getCapitalProjectsData();
    }
  }, [submitdeactivate]);

  useEffect(() => {
    getCapitalProjectsData();
  }, []);

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
    setTimeout(() => setToast(false), 4000);
  };
  const getCapitalProjectsData = async () => {
    var url = `${baseUrl}capprojects/details`;

    try {
      setLoading(true);
      const res = await getApiCall(url).then((response: any) => {
        const formattedRows = response?.data?.map((row: any) => ({
          ...row,
          isActive: row?.isActive ? "Yes" : "No",
        }));
        setRowData(formattedRows);
        setGridDropDown({
          invID: response.investmentIDs,
          invName: response.investmentNames,
          createdBy: response.createdBy,
          createdOn: response.createdOn,
          updatedBy: response.updatedBy,
          updatedAt: response.updatedAt,
        });
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      errorToastMsgwithCode(error);
      console.log("error in fetching funds", error);
    }
  };
  const addProjectdropdown = async () => {
    setSubmitClicked(false);
    setOpenDialog(true);
    setLoading(false);
    if (adHocDropdown === null || adHocDropdown === undefined) {
      var url = `${baseUrl}capprojects/invdetails`;
      try {
        setInvLoading(true);
        const res = await getApiCall(url).then((response: any) => {
          setAdHocDropdown(response);
          setInvLoading(false);
        });
      } catch (error) {
        errorToastMsgwithCode(error);
        console.log("error in fetching funds", error);
      }
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <Grid item lg={4}>
          <h2 className="text-left mstitle">Approved Capital Projects</h2>
        </Grid>
        <CapitalProjectsDataGrid
          rowdata={rowData}
          loading={loading}
          addProjectdropdown={addProjectdropdown}
          gridDropDown={gridDropDown}
          setOpen={setOpenDialog}
          updateRow={setUpdateData}
          submitClicked={submitClicked}
          type={setType}
          submitdeactivate={setdeactivate}
        />
      </div>
      {openDialog ? (
        <CapitalProjectDialogBox
          open={openDialog}
          setOpen={setOpenDialog}
          adHocDropdown={adHocDropdown}
          updateRow={updateData}
          submitClicked={setSubmitClicked}
          invLoading={invLoading}
          rowdata={rowData}
          type={type}
        />
      ) : null}
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </ThemeProvider>
  );
}

export default CapitalProjects;
