import { Autocomplete, Grid, TextareaAutosize } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { bid } from "../../constant";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getuserdetailsdata } from "../../redux/slices/userContextDetailSlice";
import { authorizationAmountChangeByAm } from "../../redux/slices/commonslice";
import { getFormData } from "../../redux/slices/getFormDataSlice";
import { updateNonBudgetedData } from "../../redux/slices/nonBudgetedDataSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toLower } from "lodash";
import { makeStyles } from "tss-react/mui";
import { updateErrorFields } from "../../redux/slices/submitFormErrorSlice";
import dayjs, { Dayjs } from 'dayjs';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import * as React from 'react';
import { useCallback } from 'react';
import _debounce from 'lodash/debounce';
import { updateAuthorizationData } from "../../redux/slices/authorizationDataSlice";

const useStyles = makeStyles()((theme) => ({
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  },
}));

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        allowNegative={false}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$ "
      />
    );
  },);
const NonBudgeted: React.FC<Record<any, any>> = (props) => {
  const formData = useAppSelector(getFormData);
  const userdata = useAppSelector(getuserdetailsdata);
  const nonBudgetedData = useAppSelector((state) => state.nonBudgetedData.data);
  const authorizationAmountByAm = useAppSelector((state) => { return state.commonData.amountChangeByAm });
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const submitErrorModel = useAppSelector((state) => state.submitFormError);
  const authorizationData = useAppSelector((state) => { return state.authorizationData.data })

  useEffect(() => {
    if (formData?.formType === false || formData?.propertyManagementCompanyName === "OMNI" && (nonBudgetedData?.initialDataSet || nonBudgetedData?.initialDataSet === undefined)) {
      dispatch(
        updateNonBudgetedData({
          initialDataSet: false,
          rsnForNonBudgeted: formData?.reasonForNonBudgetedProject,
          name: formData?.projectName,
          projectSource: formData?.projectSource,
          bid: formData?.bid,
          authorizationAmount: formData?.authAmount,
          projectDescription: formData?.description,
          duration: formData?.duration,
          nonCompetBidReason: formData?.bidReason,
          projectId: formData?.projectID,
          isSelected: props.selected.toString() === "Non-Budgeted",
          projectType: formData?.projectType,
          type: formData?.projectTypes,
          startDate: (formData?.startDate === null || formData?.startDate === undefined ? null :dayjs(formData?.startDate?.split("T")[0]) ),
          endDate: (formData?.endDate === null ||formData?.endDate ===  undefined ? null : dayjs(formData?.endDate?.split("T")[0])),
          isOmniBudgeted: (props.selected.toString() === "Budgeted" && formData?.propertyManagementCompanyName === "OMNI"),
        })
      );

    }
  }, [formData?.id]);

  const handleDebounceFn = (value: any) => {
    if (userdata.isAssetManager && formData?.isFormEditable) {
      dispatch(authorizationAmountChangeByAm(value));
    }
  }
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const handleAmountChange = (e: any) => {
    if (parseFloat(e.target.value) <= 5000000000000000000 || e.target.value === "") {
      debounceFn(e.target.value);
    }
  };

  function usePrevious(value: any) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevAmount: any = usePrevious(nonBudgetedData.authorizationAmount);
  useEffect(() => {
    if (prevAmount !== undefined && prevAmount !== null && prevAmount !== nonBudgetedData.authorizationAmount) {
      dispatch(updateAuthorizationData({
        ...authorizationData,
        selectedApprovers: []
      }));
    }
  }, [nonBudgetedData.authorizationAmount]);


  return (
    <>
      {formData?.propertyManagementCompanyName === "OMNI" && props.selected.toString() === "Budgeted" ? <></> :
        <Grid item xs={12}>
          <Grid>
            <label>Reason for the non-budgeted/existing project addition </label>
            <span className="mandatory"> *</span>
          </Grid>
          <Grid>
            <TextField fullWidth
              size="small"
              multiline
              minRows={3}
              maxRows={7}
              disabled={!formData?.isFormEditable}
              error={submitErrorModel.data.nonBudgetReason}
              value={nonBudgetedData?.rsnForNonBudgeted}
              onChange={(event) => {
                dispatch(updateNonBudgetedData({ ...nonBudgetedData, rsnForNonBudgeted: event.target.value }));
                dispatch(updateErrorFields({
                  ...submitErrorModel.data,
                  nonBudgetReason: false
                }));
              }}>
            </TextField>
          </Grid>
        </Grid>}
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project Name</label>
          <span className="mandatory"> * </span>
        </Grid>
        <Grid>
          <TextField size="small"
            fullWidth
            error={submitErrorModel.data.nbProjectName}
            disabled={!formData?.isFormEditable}
            value={nonBudgetedData?.name}
            inputProps={{
              min: 0,
              maxLength: 256,
              onPaste: (e) => {
                const pastedText = e.clipboardData.getData('text');
                if (pastedText.length > 256) {
                  e.preventDefault();
                }
              }
            }}
            onChange={(event) => {
              dispatch(updateNonBudgetedData({ ...nonBudgetedData, name: event.target.value }));
              dispatch(updateErrorFields({
                ...submitErrorModel.data,
                nbProjectName: false
              }));
            }}>
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project ID</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <TextField size="small"
            fullWidth
            error={submitErrorModel.data.nbProjectId}
            disabled={!formData?.isFormEditable}
            value={nonBudgetedData?.projectId}
            inputProps={{
              min: 0,
              maxLength: 256,
              onPaste: (e) => {
                const pastedText = e.clipboardData.getData('text');
                if (pastedText.length > 256) {
                  e.preventDefault();
                }
              }
            }}
            onChange={(event) => {
              dispatch(updateNonBudgetedData({ ...nonBudgetedData, projectId: event.target.value }));
              dispatch(updateErrorFields({
                ...submitErrorModel.data,
                nbProjectId: false
              }));
            }}></TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project Type</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <Autocomplete
            id="project-type"
            options={formData?.projectTypes || []}
            value={nonBudgetedData?.projectType ?? []}
            disabled={!formData?.isFormEditable}
            fullWidth
            size="small"
            onChange={(event, newInputValue) => {
              if (newInputValue !== null) {
                dispatch(updateNonBudgetedData({ ...nonBudgetedData, projectType: newInputValue }));
              } else {
                dispatch(updateNonBudgetedData({ ...nonBudgetedData, projectType: "" }));
              }

              dispatch(updateErrorFields({
                ...submitErrorModel.data,
                nbProjectType: false
              }));
            }}
            renderInput={(params) => <TextField {...params} error={submitErrorModel.data.nbProjectType} />}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project Source</label>
        </Grid>
        <Grid>
          <TextField size="small"
            fullWidth
            value={nonBudgetedData?.projectSource}
            disabled={!formData?.isFormEditable}
            inputProps={{
              min: 0,
              maxLength: 256,
              onPaste: (e) => {
                const pastedText = e.clipboardData.getData('text');
                if (pastedText.length > 256) {
                  e.preventDefault();
                }
              }
            }}
            onChange={(event) => {
              dispatch(updateNonBudgetedData({ ...nonBudgetedData, projectSource: event.target.value }));
            }}>
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid>
          <label>Detailed Project Description</label>
        </Grid>
        <Grid>
          <TextField fullWidth size="small"
            value={nonBudgetedData?.projectDescription}
            multiline
            minRows={3}
            maxRows={7}
            disabled={!formData?.isFormEditable}
            onChange={(event) => { dispatch(updateNonBudgetedData({ ...nonBudgetedData, projectDescription: event.target.value })); }}
          > {" "}</TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid>
          <label>Bid</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <Autocomplete
            id="bid"
            options={formData?.bids || []}
            fullWidth
            size="small"
            disabled={!formData?.isFormEditable}
            value={nonBudgetedData?.bid ?? []}
            onChange={(event, newInputValue) => {
              if (newInputValue !== null) {
                dispatch(updateNonBudgetedData({ ...nonBudgetedData, bid: newInputValue }));
                dispatch(updateErrorFields({ ...submitErrorModel.data, nbBid: false }));
                if (toLower(newInputValue) !== toLower(bid[2])) {
                  dispatch(updateNonBudgetedData({ ...nonBudgetedData, bid: newInputValue, nonCompetBidReason: "" }));
                }
              } else {
                dispatch(updateNonBudgetedData({ ...nonBudgetedData, bid: "" }));
              }

            }}
            renderInput={(params) => <TextField
              error={submitErrorModel.data.nbBid}
              {...params} />}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid>
          <label>Expected Start Date</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid className="datepicker-grid">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="MMM-DD-YYYY"
              slotProps={{
                textField: {
                  fullWidth: true, size: "small",
                  inputProps: { readOnly: true },
                  error: submitErrorModel.data.nbBudgetedstartdate
                }
              }}
              disabled={!formData?.isFormEditable}
              value={nonBudgetedData?.startDate ? dayjs(nonBudgetedData?.startDate) : null}
              onChange={(newValue) => {
                if (newValue === null) {
                  dispatch(
                    updateNonBudgetedData({
                      ...nonBudgetedData,
                      startDate: null,
                    })
                  );
                } else {
                  const newStartDate = new Date(newValue.year(), newValue.month(), newValue.date(), 12, 0, 0);
                  dispatch(
                    updateNonBudgetedData({
                      ...nonBudgetedData,
                      startDate: newStartDate,
                    })
                  );
                  dispatch(
                    updateErrorFields({
                      ...submitErrorModel.data,
                      nbBudgetedstartdate: false,
                    })
                  );
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <Grid>
          <label>Expected End Date</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid className="datepicker-grid">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="MMM-DD-YYYY"
              slotProps={{
                textField: {
                  fullWidth: true, size: "small",
                  error: submitErrorModel.data.nbBudgetedenddate, inputProps: { readOnly: true }
                }
              }}
              disabled={!formData?.isFormEditable}
              value={nonBudgetedData?.endDate ? dayjs(nonBudgetedData?.endDate) : null}
              minDate={nonBudgetedData?.startDate ? dayjs(nonBudgetedData?.startDate) : undefined}
              onChange={(newValue: any) => {
                if (newValue === null) {
                  dispatch(
                    updateNonBudgetedData({
                      ...nonBudgetedData,
                      endDate: null,
                    })
                  );
                } else {
                  const endDate = new Date(newValue.year(), newValue.month(), newValue.date(), 12, 0, 0);
                  if (dayjs(newValue).isBefore(dayjs(nonBudgetedData?.startDate), 'day')) {
                    dispatch(
                      updateNonBudgetedData({
                        ...nonBudgetedData,
                        endDate: null,
                      })
                    );

                  } else {
                    dispatch(
                      updateNonBudgetedData({
                        ...nonBudgetedData,
                        endDate: endDate,
                      })
                    );
                    dispatch(
                      updateErrorFields({
                        ...submitErrorModel.data,
                        nbBudgetedenddate: false,
                      })
                    );
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {toLower(nonBudgetedData.bid) === toLower(bid[2]) &&
        (
          <Grid item xs={12}>
            <Grid>
              <label>Reason</label>
              <span className="mandatory"> *</span>
            </Grid>
            <Grid>
              <TextField
                multiline
                minRows={3}
                maxRows={7}
                fullWidth
                size="small"
                error={submitErrorModel.data.nbBidReason}
                value={nonBudgetedData?.nonCompetBidReason}
                disabled={!formData?.isFormEditable}
                onChange={(event) => {
                  dispatch(updateNonBudgetedData({ ...nonBudgetedData, nonCompetBidReason: event.target.value }));
                  dispatch(updateErrorFields({ ...submitErrorModel.data, nbBidReason: false }));
                }}
              ></TextField>
            </Grid>
          </Grid>
        )}
      <Grid item md={6} className="grid-hide">
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Authorization Amount</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <TextField
            fullWidth
            size="small"
            InputProps={{
              inputComponent: NumericFormatCustom as any
            }}
            disabled={!formData?.isFormEditable}
            value={nonBudgetedData?.authorizationAmount}
            onChange={(event) => {
              handleAmountChange(event);
              dispatch(updateNonBudgetedData({ ...nonBudgetedData, authorizationAmount: parseFloat(event.target.value) }));
              dispatch(updateErrorFields({ ...submitErrorModel.data, nbAuthAmount: false, authAmountError: false }));
            }}
            error={submitErrorModel.data.nbAuthAmount}
            inputProps={{ min: 0, style: { textAlign: 'right' }, maxLength: 20 }}
            className={classes.classes.input}>
          </TextField>
        </Grid>
      </Grid>

    </>
  );
};

export default NonBudgeted;
