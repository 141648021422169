import { Autocomplete, Box, Button, Checkbox, Grid, TextField } from "@mui/material";
import {
    DataGridPro, GridHeaderFilterCellProps, GridLoadingOverlay, GridRenderHeaderFilterProps,
    useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import fundsViewsColumn from "../Funds/fundsViewsColumn.json";
import { toUpper } from "lodash";
import * as XLSX from 'xlsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from "@mui/lab";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FundsDataGrid = (props: any) => {

    const [rowData, setRowData] = useState<any>();
    const [headingFilters, setHeadingFilters] = useState<any>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [excelLoading, setExcelLoading] = useState<boolean>(false);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });
    const [filterModelState, setFilterModelState] = useState<any>();
    useEffect(() => {
        setRowData(props.rowdata);
        setHeadingFilters({ name: props.fundNameDropdown, status: props.statusDropDown });
        if (filterModelState?.items !== undefined) {
            filterData(filterModelState);
          }
    }, [props.rowdata]);

    const exportExcel = () => {
        setExcelLoading(true);
        const data = rowData.map((row: any) =>
            fundsViewsColumn.colDef.map(col => row[col.field] || '')
        );
        const worksheet: any = XLSX.utils.aoa_to_sheet([
            fundsViewsColumn.colDef.map(col => col.headerName),
            ...data,
        ]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'DataGrid');
        XLSX.writeFile(workbook, 'funds.xlsx');
        setExcelLoading(false);
    };

    const CustomTextHeaderFilter = (params: GridRenderHeaderFilterProps) => {
        const apiRef = useGridApiContext();
        const { colDef, inputRef } = params;
        return (
          <TextField
            id="standard-basic"
            variant="standard"
            onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
            key={props.selectedTab || props.isActive}
            onChange={(event) => {
              handleFilterChange(event.target.value, params.item.field);
            }}
            InputProps={{
              ref: inputRef
            }}
          />
        );
      };

    const dropDownFiltering = (value: Array<string>, field: string, filteredRows: any) => {
        var filteredDropdownData: any = [];

        value.forEach((selectedDropdown: string) => {
            var filteredData = filteredRows.filter((fundRow: any) => fundRow[field] !== undefined && (toUpper(fundRow[field]) === toUpper(selectedDropdown)));

            filteredData.forEach((perFilterd: any) => {
                filteredDropdownData.push(perFilterd);
            });
        });

        return filteredDropdownData;
    }

    const handleFilterChange = (e: any, field: string) => {
        var value = e;
        var fieldName = field;
        var filterModel = {
            items: [],
        }
        setSelectedOptions({
            ...selectedOptions,
            ...{
                [fieldName]: value,
            },
        });
        filterModel.items = {
            ...selectedOptions,
            ...{
                [fieldName]: value,
            },
        };
        filterData(filterModel);
        setFilterModelState(filterModel);
    };

    const filterData = (filterModel: any) => {
        var filteredRowData: any = props.rowdata;
        for (const key of Object.keys(filterModel.items)) {
            switch (typeof (filterModel.items[key])) {
                case "object":
                    if (filterModel.items[key].length !== 0) {
                        filteredRowData = dropDownFiltering(filterModel.items[key], key, filteredRowData);
                    }
                    break;
                case "string":
                    if (filterModel.items[key].length !== 0) {
                        filteredRowData = filteredRowData?.filter((fundRow: any) => fundRow[key] !== undefined && toUpper(fundRow[key]).includes(toUpper(filterModel.items[key])));
                    }
                    break;
                default:
                    break;
            }
        }
        setRowData(filteredRowData);
    }

    const CustomDropDownHeaderFilter = (params: GridRenderHeaderFilterProps) => {
        const apiRef = useGridApiContext();
        var columnFilterList = headingFilters[params.item.field] || [];
        const { colDef, inputRef } = params;
    
        return (
          <Autocomplete
            className="autocomplete-root"
            ListboxProps={{
              className: "autocomplete-dashboard"
            }}
            multiple
            limitTags={1}
            id="tags-standard"
            onChange={(event, newInputValue) => {
              handleFilterChange(newInputValue, params.item.field);
            }}
            onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
            options={columnFilterList}
            disableCloseOnSelect
            renderOption={(props, option: any, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option}
              </li>
            )}
            fullWidth
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
        );
      };

    const columns: any = fundsViewsColumn.colDef.map((colDef: any) => {

        let { valueFormatter, ...rest } = colDef;
        if (
            colDef.field === "name" ||
            colDef.field === "status"
        ) {
            return {
                ...rest,
                renderHeaderFilter: CustomDropDownHeaderFilter,
            };
        } else {
            return {
                ...rest,
                renderHeaderFilter: CustomTextHeaderFilter,
            };
        }
    });

    return (
        <>
            <Grid item lg={12} className="master-excel-grid" >
                <LoadingButton
                    variant="contained"
                    loading = {excelLoading}
                    className="btn-reports"
                    onClick={exportExcel}
                ><FileDownloadOutlinedIcon sx={{ mr: "0.5px" }} />EXCEL</LoadingButton>
            </Grid>
            <Box className="dashboard-table-wrapper">
                <div style={{ width: "100%" }}>
                    <DataGridPro
                        className="dashboard-dataGrid"
                        slots={{
                            loadingOverlay: GridLoadingOverlay,
                          }}
                          sx={{
                            pointerEvents: props.loading ? 'none' : 'auto',
                            opacity: props.loading ? 0.7 : 1,
                          }}
                        getRowId={(row) => row.id}
                        rows={rowData ?? []}
                        columns={columns}
                        headerFilters
                        columnHeaderHeight={48}
                        headerFilterHeight={48}
                        pageSizeOptions={[25, 50, 100]}
                        rowHeight={40}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        loading={props.loading}
                        pagination
                        disableColumnMenu={true}
                        autoHeight
                        hideFooter={false}
                    />
                </div>
            </Box>
        </>
    );
};

export default FundsDataGrid;
