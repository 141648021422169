import React, { useCallback, useEffect } from 'react';
import { useAppState } from './authorizationSectionHelper/useStateHooks';

const CapitalAuthSection = (props: any) => {
    const appState = useAppState();
    const handleNAA_CA = () => {
        if (props?.constants.isAmtHigherDefined || (props?.constants.isAuthAmountValid && !appState?.userdata.isPropertyManager && !appState?.userdata.isAdmin)) {
            props.localState.setShowValuationText(true);
            if (appState?.formData?.isFormEditable || appState?.formData?.showApprovers) {
                props?.handleValuationChange(appState?.formData?.isAmtHigherThanValuationPercentage ? "Yes" : "No");
            }
        }
    };
    // Effect for handling valuation text visibility
    useEffect(() => {
        if (props?.constants.isNAA_CA && !props?.constants.isAssetManagerWithEditAccess) {
            handleNAA_CA();
        }
    }, [appState?.formData]);

    useEffect(() => {
        if (props?.constants.isBudgeted && props?.constants.isNonOmni && props?.constants.isAssetManagerWithEditAccess) {
            if (props?.constants.isBudgetedAuthAmountValid) {
                props.localState.authApproversRequest.amount = appState.budgetedData.data.authAmount;
                props.localState.authApproversRequest.approvedAmount = appState?.approvedAmount;
                if (props?.constants.isTIAA) {
                    props?.fetchApprovers();
                    props?.localState.setShowApprovers(true);
                } else if (props?.constants.isNAA_CA) {
                    props?.localState.setShowValuationText(true);
                    if (props?.constants.isAuthDataValuationPer && appState?.formData?.isFormEditable) {
                        props?.handleValuationChange(appState?.authorizationData.isAmtHigherThanValuationPercentage ? "Yes" : "No");

                    }

                }
            } else if (props?.constants.isAuthBudgedNonBudgetValid) {
                props?.resetApprovers();
            }
        } else {
            if (props?.constants.isNonBudgetedAuthAmountValid && props?.constants.isAssetManagerWithEditAccess) {
                props.localState.authApproversRequest.amount = appState.nonBudgetedData.data.authorizationAmount;
                props.localState.authApproversRequest.approvedAmount = 0;
                props.localState.authApproversRequest.isOmniBudgeted = (appState.formData?.propertyManagementCompanyName === "OMNI" && appState.capitalAuthFormType);
                if (props?.constants.isTIAA) {
                    props?.fetchApprovers();
                    props?.localState.setShowApprovers(true);
                } else if (props?.constants.isNAA_CA) {
                    props?.localState.setShowValuationText(true);
                    if (props?.constants.isAuthDataValuationPer && appState?.formData?.isFormEditable) {
                        props?.handleValuationChange(appState?.authorizationData.isAmtHigherThanValuationPercentage ? "Yes" : "No");

                    }
                }
                else if (props?.constants.isAuthBudgedNonBudgetValid) {
                    props?.resetApprovers();
                }
            }
        }
    }, [appState?.approvedAmount, appState?.capitalAuthFormType, appState?.authAmountChangeByAm, props?.constants.isBudgetedAuthAmountValid, props?.constants.isNonBudgetedAuthAmountValid]);

    return null;
};

export default CapitalAuthSection;
