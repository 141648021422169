// components/utils/GlobalErrorDisplay.tsx
import React, { useEffect, useState } from 'react';
import { useGlobalErrors } from '../../hooks/useGlobalError';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const GlobalErrorDisplay = () => {
  const { errors, clearError } = useGlobalErrors();
  const [openErrors, setOpenErrors] = useState<string[]>([]);

  // Handle new errors
  useEffect(() => {
    const errorKeys = Object.keys(errors);
    setOpenErrors((prev) => {
      // Create a unique array by filtering out duplicates
      const combined = [...prev, ...errorKeys];
      return combined.filter((value, index) => combined.indexOf(value) === index);
    });
  }, [errors]);

  const handleClose = (actionType: string) => {
    setOpenErrors((prev) => prev.filter(key => key !== actionType));
    clearError(actionType);
  };

  return (
    <>
      {openErrors.map((actionType) => {
        const error = errors[actionType];
        if (!error) return null;

        return (
          <Snackbar
            key={actionType}
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => handleClose(actionType)}
          >
            <StyledAlert
              onClose={() => handleClose(actionType)}
              severity="error"
              variant="filled"
              elevation={6}
            >
              {error.message}
            </StyledAlert>
          </Snackbar>
        );
      })}
    </>
  );
};
