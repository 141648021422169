import "./App.css";
import {
  Configuration,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import CapitalAuthorization from "./pages/CapitalAuthorizations";
import Footer from "./Layout/Footer";

import HeaderRoot from "./Layout/Header/HeaderRoot";
import { LicenseInfo } from "@mui/x-license-pro";
import Verbiage from "./pages/Settings/Verbiagemasterscreen/Verbiage";
import { createBrowserRouter, RouterProvider, Outlet, Navigate, useParams } from "react-router-dom";

import {
  useMsal,
  MsalAuthenticationTemplate,
  MsalProvider,
} from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import { Roles } from "./interfaces/Interfaces";
import { CurrentUser } from "./interfaces/Interfaces";
import useConfig from "./hooks/common-hooks";
import Dashboard from "./pages/Dashboard";
import AuthorizationTypes from "./pages/Settings/AuthorizationTypes/AuthorizationTypes";
import Fund from "./pages/Settings/Funds/Fund";
import Administration from "./pages/Reports/AdministrationOfInv/Administration";
import ActivitySummary from "./pages/Reports/InvestmentActivitySummary/ActivitySummary";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { updateCurrentUser } from "./redux/slices/currentUserSlice";
import { getUserData, getuserdetailsdata } from "./redux/slices/userContextDetailSlice";
import ConditionalRoute from "./routes/ConditionalRouting";
import VerbiageMS from "./pages/Settings/Verbiagemasterscreen/Verbiage";
import CapitalProjects from "./pages/Settings/CapitalProjects/CapitalProjects";
import Approvers from "./pages/Settings/Approversmasterscreen/Approvers";
import ApproverLevel from "./pages/Settings/ApproverLevel/ApproverLevel";
import { SessionTimeoutPopup } from "./components/notifications/sessionTimeoutPopup";
import FundsEmail from "./pages/Settings/Funds_email/FundsEmail";
import { GlobalErrorDisplay } from "./components/utils/GlobalErrorDisplay";
import AttestationDashboard from "./attestation/AttesDashboard";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY as string);

const AuthProvider = (props: any) => {
  const [config] = useConfig();

  if (!config?.msalConfig?.auth) return <>Loading config...</>;

  const configuration: Configuration = { ...config.msalConfig };

  const pca = new PublicClientApplication(configuration);

  return <MsalProvider instance={pca}>{props.children}</MsalProvider>;
};


export const Main = () => {
  const { accounts, instance } = useMsal();
  const [loggedInUserRole, setLoggedInUserRole] = useState<Roles>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const userdetailsdata = useAppSelector((state) => state.userContextDetail.loading);
  const [authId, setAuthId] = useState<string>('');
  
  useEffect(() => {
  if (accounts && accounts.length > 0) {
    const account = accounts[0];
    instance.setActiveAccount(account);

    const currentUser: CurrentUser = {
      name: account?.name,
      email: account?.username,
      tenantId: account?.tenantId,
    };
    
      dispatch(updateCurrentUser(currentUser));
   
    
  } }, [])


  const signOutClickHandler = () => {
    const logoutRequest = {
      postLogoutRedirectUri: "/",
    };
    localStorage.setItem("dashBoardPayload", JSON.stringify({}));
    localStorage.removeItem("invType");
    instance.logoutRedirect(logoutRequest);
  };
  
  useEffect(() => {
    dispatch(getUserData(accounts[0].username));
  }, []);

  const router = createBrowserRouter([
    {
      element: (
        <>

          <HeaderRoot
            accounts={accounts}
            signOutClickHandler={signOutClickHandler}
            isAdmin={loggedInUserRole?.isAdmin}
            setAuthId={setAuthId}
          />
        </>
      ),
      errorElement: (
        <>
          <h1>Error 404 - Page Not Found</h1>
        </>
      ),
      children: [
        {
          path: "/",
          element: <Navigate to="/authorizations/dashboard" />,
        },
        {
          path: "/authorizations/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/authorizations/settings/types",
          element: <AuthorizationTypes />,
        },
        {
          path: "/authorizations/settings/approverlevels",
          element: <ApproverLevel />,
        },
        {
          path: "/authorizations/settings/verbiage",
          element: <VerbiageMS />,
        },
        {
          path: "/authorizations/settings/fundsemail",
          element: <FundsEmail />,
        },
        {
          path: "/authorizations/settings/funds",
          element: <Fund />,
        },
        {
          path: "/authorizations/settings/approvers",
          element: <Approvers />,
        },
        {
          path: "/authorizations/settings/approvedCapitalProjects",
          element: <CapitalProjects />,
        },
        {
          path: "/authorizations/reports/administrationOfInvestments",
          element: <Administration />,
        },
        {
          path: "/authorizations/reports/investmentActivitySummary",
          element: <ActivitySummary />,
        },
        {
          path: "/authorizations/form/:id",
          element: <ConditionalRoute />,
        },
        {
          path: "/attestations/dashboard",
          element: <AttestationDashboard />,
        },
        {
          path: "*",
          element: <Navigate to="/authorizations/dashboard" />,
        },

      ],
    },
  ]);

  return (
    <div className="main-container">
      <React.Fragment>
      <SessionTimeoutPopup />
      <GlobalErrorDisplay />
        {!userdetailsdata && 
          <RouterProvider router={router} />}
      </React.Fragment>
      <Footer />
    </div>
  );
};

function App() {
  function ErrorComponent(error: any) {
    return <p>An Error Occurred: {error}</p>;
  }

  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }

  return (
    <AuthProvider>

      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Main />
      </MsalAuthenticationTemplate>
    </AuthProvider>
  );
}

export default App;
