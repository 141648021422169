import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthorizationRequest, } from "../../interfaces/Interfaces";
import { FormData } from "../../interfaces/Interfaces";
import { baseUrl } from "../../components/config/Config";
import { getApiCall } from "../../services/ApiHelper";
import { useAppDispatch } from "../hooks";
import { updateBudgetedData } from "./budgetedDataSlice";
import { updateNonBudgetedData } from "./nonBudgetedDataSlice";

type InitialState = {
    formData: any | null,
    error: string,
    loading: boolean
}

const initialState: InitialState = {
    formData: null,
    error: '',
    loading: false,
}

export const getFormDataById = createAsyncThunk("getFormInfo", async (id: any, {rejectWithValue}) => {
    try {
        var url = `${baseUrl}Authorizations/dataById?id=${id}`;
        const response = await getApiCall<any>(url);
        return response;
    } catch (error: any) {
        console.error('Error in GET request:', error);
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const getFormDataSlice = createSlice({
    name: 'formData',
    initialState,
    reducers: {
        resetState: (state) => {
            state.formData = null as any;
            state.error = "";
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFormDataById.pending, (state) => {
            state.loading = true;
        })
            .addCase(getFormDataById.fulfilled, (state, action: PayloadAction<FormData | null>) => {
                state.loading = false;
                state.formData = action.payload;
            })
            .addCase(getFormDataById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const getFormData = (state: any) => state.getFormData.formData
export const { resetState: resetFormDataStateAction } = getFormDataSlice.actions;


export default getFormDataSlice.reducer;
