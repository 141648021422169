import { Autocomplete, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { bid } from "../../constant";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateBudgetedData } from "../../redux/slices/budgetedDataSlice";
import { updateErrorFields } from "../../redux/slices/submitFormErrorSlice";
import { getuserdetailsdata } from "../../redux/slices/userContextDetailSlice";
import { debounce, toLower } from "lodash";
import { authorizationAmountChangeByAm } from "../../redux/slices/commonslice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "tss-react/mui";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import * as React from 'react';
import { useCallback } from 'react';
import _debounce from 'lodash/debounce';
import dayjs, { Dayjs } from "dayjs";
import { getFormData } from "../../redux/slices/getFormDataSlice";
import { updateAuthorizationData } from "../../redux/slices/authorizationDataSlice";


const useStyles = makeStyles()((theme) => ({
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  },
}));


interface CustomProps {
  onChange: (event: { target: { name: string; value: any } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        allowNegative={false}
        onValueChange={(values) => {
          const floatValue = values?.floatValue || 0;
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$ "
      />
    );
  },
);

const Budgeted: React.FC<Record<any, any>> = (props) => {
  const formData = useAppSelector((state) => state.getFormData.formData);
  const userdata = useAppSelector(getuserdetailsdata);
  const budgetedData = useAppSelector((state) => state.budgetedData.data)
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const submitErrorModel = useAppSelector((state) => state.submitFormError);
  const authorizationData = useAppSelector((state) => { return state.authorizationData.data });
  const formType = useAppSelector((state) => { return state.commonData.formType });

  useEffect(() => {
    if ((formData?.formType === true || formData?.formType === null) && (budgetedData?.initialDataSet || budgetedData?.initialDataSet === undefined)) {
      dispatch(
        updateBudgetedData({
          initialDataSet: false,
          name: formData?.projectName,
          projectId: formData?.projectID,
          projectSource: formData?.projectSource,
          authAmount: formData?.authAmount,
          projectDescription: formData?.description,
          duration: formData?.duration,
          isSelected: props.selected.toString() === "budgeted",
          budgetedAmount: formData?.approvedProjectBudgetAmount,
          type: formData?.type,
          projectType: formData?.projectType,
          bid: formData?.bid,
          startDate: formType ? (formData?.startDate === null || formData?.startDate === undefined ? null : dayjs(formData?.startDate?.split("T")[0]) ) : null,
          endDate: formType ? (formData?.endDate === null || formData?.endDate === undefined ? null : dayjs(formData?.endDate?.split("T")[0]) ) : null,
          overBudgetReason: formData?.overBudgetReason,
          nonCompetBidReason: formData?.bidReason,
        })
      );
    }

  }, [formData?.id]);

  const handleDebounceFn = (value: any) => {
    if (userdata.isAssetManager && formData?.isFormEditable) {
      dispatch(authorizationAmountChangeByAm(value));
    }
  }

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const handleAmountChange = (e: any) => {
    if (parseFloat(e.target.value) <= 5000000000000000000 || e.target.value === "") {
      debounceFn(e.target.value);
    }
  };

  function usePrevious(value: any) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevAmount: any = usePrevious(budgetedData.authAmount);

  useEffect(() => {
    if (prevAmount !== undefined && prevAmount !== null && prevAmount !== budgetedData.authAmount) {
      dispatch(updateAuthorizationData({
        ...authorizationData,
        selectedApprovers: []
      }));
    }
  }, [budgetedData.authAmount]);

  return (
    <>
      <Grid item xs={12} md={8}>
        <Grid>
          <label>Select Project</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <Autocomplete
            disablePortal
            id="property-select"
            value={formData?.projectDetails?.find((option: any) => option.name === budgetedData?.name) !== undefined ? formData?.projectDetails?.find((option: any) => option.name === budgetedData?.name) :
              {
                name: formData?.formType ? (formData?.projectName ? formData?.projectName : "") : "",
                id: formData?.formType ? (formData?.projectID ? formData?.projectID : "") : "",
                source: formData?.formType ? (formData?.projectSource ? formData?.projectSource : "") : "",
                invId: formData?.formType ? (formData?.invId ? formData?.invId : "") : "",
                budgetedAmount: formData?.formType ? (formData?.approvedProjectBudgetAmount ? formData?.approvedProjectBudgetAmount : "") : ""
              }}
            disabled={!formData?.isFormEditable}
            options={formData?.projectDetails ?? []}
            getOptionLabel={(option: any) => option?.name}
            isOptionEqualToValue={(option: any, value: any) => {
              return option?.name === value?.name;
            }}
            onChange={(event, newInputValue: any) => {
              dispatch(
                updateBudgetedData({
                  ...budgetedData,
                  projectId: newInputValue ? newInputValue?.id : "",
                  name: newInputValue ? newInputValue?.name : "",
                  projectSource: newInputValue ? newInputValue?.source : "",
                  budgetedAmount: newInputValue ? newInputValue?.budgetedAmount : "",
                })
              );
              dispatch(updateAuthorizationData({
                ...authorizationData,
                selectedApprovers: []
              }));
              dispatch(
                updateErrorFields({
                  ...submitErrorModel.data,
                  selectProject: false,
                })
              );
            }}
            size='small' fullWidth={true}
            renderInput={(params) => <TextField
              error={submitErrorModel.data.selectProject}
              {...params} />}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project Name</label><span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <TextField size="small"
            fullWidth={true}
            value={budgetedData?.name}
            inputProps={{
              min: 0,
              maxLength: 256,
              onPaste: (e) => {
                const pastedText = e.clipboardData.getData('text');
                if (pastedText.length > 256) {
                  e.preventDefault();
                }
              }
            }}
            error={submitErrorModel.data.selectProject} disabled></TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project ID</label>
        </Grid>
        <Grid>
          <TextField size="small" fullWidth={true} value={budgetedData?.projectId}
            inputProps={{
              min: 0,
              maxLength: 256,
              onPaste: (e) => {
                const pastedText = e.clipboardData.getData('text');
                if (pastedText.length > 256) {
                  e.preventDefault();
                }
              }
            }} disabled>{" "} </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project Type</label><span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <Autocomplete
            id="project-type"
            options={formData?.projectTypes || []}
            value={budgetedData?.projectType ?? []}
            renderInput={(params) => <TextField {...params} error={submitErrorModel.data.projectType} />}
            disabled={!formData?.isFormEditable}
            size="small" fullWidth={true}
            onChange={(event, newInputValue) => {
              if (newInputValue !== null) {
                dispatch(updateBudgetedData({ ...budgetedData, projectType: newInputValue }));
              } else {
                dispatch(updateBudgetedData({ ...budgetedData, projectType: "" }));
              }
              dispatch(updateErrorFields({
                ...submitErrorModel.data,
                projectType: false
              }));

            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Project Source</label>
        </Grid>
        <Grid>
          <TextField size="small" fullWidth={true} inputProps={{
            min: 0,
            maxLength: 256,
            onPaste: (e) => {
              const pastedText = e.clipboardData.getData('text');
              if (pastedText.length > 256) {
                e.preventDefault();
              }
            }
          }} value={budgetedData?.projectSource} disabled></TextField>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid>
          <label>Detailed Project Description</label>
        </Grid>
        <Grid>
          <TextField
            value={budgetedData.projectDescription}
            disabled={!formData?.isFormEditable}
            multiline
            minRows={3}
            maxRows={7}
            size='small' fullWidth={true}
            onChange={(event) => {
              dispatch(updateBudgetedData({ ...budgetedData, projectDescription: event.target.value }));
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid>
          <label>Bid</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <Autocomplete
            id="bid"
            options={formData?.bids || []}
            size="small"
            fullWidth
            disabled={!formData?.isFormEditable}
            value={budgetedData?.bid ?? []}
            onChange={(event, newInputValue) => {
              if (newInputValue !== null) {
                dispatch(updateBudgetedData({ ...budgetedData, bid: newInputValue }));
                dispatch(updateErrorFields({ ...submitErrorModel.data, bid: false }));

                if (toLower(newInputValue) !== toLower(bid[2])) {
                  dispatch(updateBudgetedData({ ...budgetedData, bid: newInputValue, nonCompetBidReason: "" }));
                }
              } else {
                dispatch(updateBudgetedData({ ...budgetedData, bid: "" }));
              }
            }}
            renderInput={(params) => <TextField
              error={submitErrorModel.data.bid}
              {...params} />}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid>
          <label>Expected Start Date</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid className="datepicker-grid">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="MMM-DD-YYYY"
              slotProps={{
                textField: {
                  fullWidth: true, size: "small",
                  inputProps: { readOnly: true },
                  error: submitErrorModel.data.budgetedstartdate
                }
              }}
              disabled={!formData?.isFormEditable}
              value={budgetedData?.startDate ? dayjs(budgetedData?.startDate) : null}
              onChange={(newValue) => {
                if (newValue === null) {
                  dispatch(
                    updateBudgetedData({
                      ...budgetedData,
                      startDate: null,
                    })
                  );
                } else if (dayjs.isDayjs(newValue) && newValue.isValid()) {
                  const newStartDate = new Date(newValue.year(), newValue.month(), newValue.date(), 12, 0, 0);
                  dispatch(
                    updateBudgetedData({
                      ...budgetedData,
                      startDate: newStartDate,
                    })
                  );
                  dispatch(
                    updateErrorFields({
                      ...submitErrorModel.data,
                      budgetedstartdate: false,
                    })
                  );
                }
              }}
            />

          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <Grid>
          <label>Expected End Date</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid className="datepicker-grid">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="MMM-DD-YYYY"
              slotProps={{
                textField: {
                  fullWidth: true, size: "small",
                  error: submitErrorModel.data.budgetedenddate, inputProps: { readOnly: true }
                }
              }}
              disabled={!formData?.isFormEditable}
              value={budgetedData?.endDate ? dayjs(budgetedData?.endDate) : null}
              minDate={budgetedData?.startDate ? dayjs(budgetedData?.startDate)  : undefined}
              onChange={(newValue: any) => {
                if (newValue === null) {
                  dispatch(
                    updateBudgetedData({
                      ...budgetedData,
                      endDate: null,
                    })
                  );
                } else if (dayjs.isDayjs(newValue) && newValue.isValid()){
                  const endDate = new Date(newValue.year(), newValue.month(), newValue.date(), 12, 0, 0);
                  if (dayjs(newValue).isBefore(dayjs(budgetedData?.startDate), 'day')) {
                    dispatch(
                      updateBudgetedData({
                        ...budgetedData,
                        endDate: null,
                      })
                    );
                  }
                  else {
                    dispatch(
                      updateBudgetedData({
                        ...budgetedData,
                        endDate: endDate,
                      })
                    );
                    dispatch(
                      updateErrorFields({
                        ...submitErrorModel.data,
                        budgetedenddate: false,
                      })
                    );
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {
        toLower(budgetedData.bid) === toLower(bid[2]) ?
          (
            <Grid item xs={12}>
              <Grid>
                <label>Reason</label>
                <span className="mandatory"> *</span>
              </Grid>
              <Grid>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={7}
                  fullWidth
                  size="small"
                  error={submitErrorModel.data.bidReason}
                  value={budgetedData.nonCompetBidReason}
                  disabled={!formData?.isFormEditable}
                  onChange={(event) => {
                    dispatch(updateErrorFields({
                      ...submitErrorModel.data,
                      bidReason: false
                    }));
                    dispatch(updateBudgetedData({ ...budgetedData, nonCompetBidReason: event.target.value }));
                  }}
                ></TextField>
              </Grid>
            </Grid>
          ) : null
      }

      <Grid item xs={12} md={6}>
        <Grid>
          <label>Remaining Approved Project Budget Amount</label>
        </Grid>
        <Grid>
          <TextField InputProps={{
            inputComponent: NumericFormatCustom as any
          }} inputProps={{ min: 0, style: { textAlign: 'right' } }}
            fullWidth size="small"
            value={budgetedData?.budgetedAmount}
            disabled></TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid>
          <label>Authorization Amount</label>
          <span className="mandatory"> *</span>
        </Grid>
        <Grid>
          <TextField
            InputProps={{
              inputComponent: NumericFormatCustom as any
            }}
            inputProps={{ min: 0, style: { textAlign: 'right' }, maxLength: 20 }}
            fullWidth
            size="small"
            disabled={!formData?.isFormEditable}
            className={classes.classes.input}
            error={submitErrorModel.data.authAmount}
            value={budgetedData?.authAmount}
            onChange={(event) => {

              handleAmountChange(event);
              dispatch(updateBudgetedData({ ...budgetedData, authAmount: parseFloat(event.target.value) }));
              dispatch(updateErrorFields({ ...submitErrorModel.data, authAmount: false, authAmountError: false }));

              if (parseFloat(event.target.value) < budgetedData?.budgetedAmount) {
                dispatch(updateBudgetedData({ ...budgetedData, authAmount: parseFloat(event.target.value) }));

              }
            }}
          >
          </TextField>
        </Grid>
      </Grid >
      {budgetedData?.authAmount > budgetedData?.budgetedAmount && (
        <Grid item xs={12}>
          <Grid>
            <label>Reason For Exceeding The Budget Amount</label>
            <span className="mandatory"> *</span>
          </Grid>
          <Grid>
            <TextField
              minRows={3}
              multiline
              maxRows={7}
              fullWidth
              size="small"
              error={submitErrorModel.data.exceededAuthAmount}
              value={budgetedData.overBudgetReason}
              disabled={!formData?.isFormEditable}
              onChange={(event) => {
                dispatch(
                  updateErrorFields({
                    ...submitErrorModel.data,
                    exceededAuthAmount: false,
                  })
                );
                dispatch(
                  updateBudgetedData({
                    ...budgetedData,
                    overBudgetReason: event.target.value,
                  })
                );
              }}
              margin="normal"
            ></TextField>
          </Grid>
        </Grid>
      )
      }
    </>
  );
};


export default Budgeted;
