import React from 'react';
import { Grid, Autocomplete, TextField } from '@mui/material';
import { useAppState } from './useStateHooks';

const ApproverField : React.FC<any>= ({ index, value,formData, onChangeSelecteApprovers, submitErrorModel }) => {
  const appState = useAppState();
  return (
    <Grid item xs={12} md={6} key={index}>
      <Grid>
        <label>Approver {index + 1}</label> <span className="mandatory"> * </span>
      </Grid>
      <Grid>
        <Autocomplete
          size="small"
          fullWidth
          disablePortal
          data-testid="property-select approval"
          disabled={!formData?.isFormEditable && !formData?.showApprovers}
          value={appState?.authorizationData?.selectedApprovers?.find((elm: any) => elm.approver === index + 1) || null}
          options={value?.approvers ?? []}
          getOptionLabel={(option: any) => option?.name}
          isOptionEqualToValue={(option: any, value: any) => option?.name === value?.name}
          onChange={(event, newInputValue) => onChangeSelecteApprovers(index, newInputValue, value)}
          renderInput={(params) => <TextField {...params} error={submitErrorModel.data.approversListError[index]?.isEmpty} />}
        />
      </Grid>
      <Grid className="grid-padding-level">
        <TextField disabled={true} fullWidth size="small" value={value.level}></TextField>
      </Grid>
    </Grid>
  );
};

export default ApproverField;
