import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridHeaderFilterCellProps,
  GridLoadingOverlay,
  GridRenderHeaderFilterProps,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ApproverLevelColumn } from "./ApproverLevelColumn";
import { toUpper } from "lodash";
import * as XLSX from "xlsx";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import dayjs, { Dayjs } from "dayjs";
import { GridValueFormatterParams } from "../../../interfaces/Interfaces";
import { LoadingButton } from "@mui/lab";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ApproverLevelDataGrid = (props: any) => {
  const [rowData, setRowData] = useState<any>();
  const [headingFilters, setHeadingFilters] = useState<any>({});
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [excelLoading, setExcelLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [filterModelState, setFilterModelState] = useState<any>();
  useEffect(() => {
    setRowData(props.rowdata);
    if (filterModelState?.items !== undefined) {
      filterData(filterModelState);
  }
    setHeadingFilters(props.gridDropDown);
  }, [props.rowdata]);

  const exportExcel = () => {
    setExcelLoading(true);
    const data = rowData.map((row: any) =>
      ApproverLevelColumn.map((col) => row[col.field] || "")
    );
    const worksheet: any = XLSX.utils.aoa_to_sheet([
      ApproverLevelColumn.map((col) => col.headerName),
      ...data,
    ]);
    // Define the columns that contain amount values
    const amountColumns = ["upperLimit", "lowerLimit"];

    // Apply right alignment to amount columns
    amountColumns.forEach((colName) => {
      const col = ApproverLevelColumn.findIndex((col) => col.field === colName);
      if (col !== -1) {
        const colLetter = XLSX.utils.encode_col(col);
        worksheet["!cols"] = worksheet["!cols"] || [];
        worksheet["!cols"][col] = {
          wch: 15,
          alignment: { horizontal: "right" },
        };

        for (let row = 2; row <= data.length + 1; row++) {
          const cellRef = `${colLetter}${row}`;
          if (worksheet[cellRef]) {
            worksheet[cellRef].z = "$#,##0.00"; // Apply number format
          }
        }
      }
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DataGrid");
    const now = dayjs();
    const hour = now.format("HH");
    const minute = now.format("mm");
    const day = now.format("DD");
    const month = now.format("MM");
    const year = now.format("YYYY");
    const seconds = now.format("ss");
    const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;
    XLSX.writeFile(workbook, `approver_level_${timestamp}.xlsx`);
    setExcelLoading(false);
  };

  const CustomTextHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    return (
      <TextField
        id="standard-basic"
        variant="standard"
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        key={props.selectedTab || props.isActive}
        onChange={(event) => {
          handleFilterChange(event.target.value, params.item.field);
        }}
        InputProps={{
          ref: inputRef
        }}
      />
    );
  };

  const dropDownFiltering = (
    value: Array<string>,
    field: string,
    filteredRows: any
  ) => {
    var filteredDropdownData: any = [];

    value.forEach((selectedDropdown: string) => {
      var filteredData = filteredRows.filter(
        (fundRow: any) =>
          fundRow[field] !== undefined &&
          toUpper(fundRow[field]) === toUpper(selectedDropdown)
      );

      filteredData.forEach((perFilterd: any) => {
        filteredDropdownData.push(perFilterd);
      });
    });

    return filteredDropdownData;
  };

  const handleFilterChange = (e: any, field: string) => {
    var value = e;
    var fieldName = field;
    var filterModel = {
      items: [],
    };
    setSelectedOptions({
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    });
    filterModel.items = {
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    };
    filterData(filterModel);
    setFilterModelState(filterModel);
  };

  const filterData = (filterModel: any) => {
    var filteredRowData: any = props.rowdata;

    for (const key of Object.keys(filterModel.items)) {
      switch (typeof filterModel.items[key]) {
        case "object":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = dropDownFiltering(
              filterModel.items[key],
              key,
              filteredRowData
            );
          }
          break;
        case "string":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = filteredRowData?.filter(
              (fundRow: any) =>
                fundRow[key] !== undefined &&
                toUpper(fundRow[key]).includes(toUpper(filterModel.items[key]))
            );
          }
          break;
        default:
          break;
      }
    }
    setRowData(filteredRowData);
  };

 const CustomDropDownHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    var columnFilterList = headingFilters[params.item.field] || [];
    const { colDef, inputRef } = params;

    return (
      <Autocomplete
        className="autocomplete-root"
        ListboxProps={{
          className: "autocomplete-dashboard"
        }}
        multiple
        limitTags={1}
        id="tags-standard"
        onChange={(event, newInputValue) => {
          handleFilterChange(newInputValue, params.item.field);
        }
        }
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        options={columnFilterList}
        disableCloseOnSelect
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}
          </li>
        )}
        fullWidth
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    );
  };

  const columns: any = ApproverLevelColumn.map((colDef: any) => {
    let { valueFormatter, ...rest } = colDef;
    if (
      colDef.field === "approverLevels" ||
      colDef.field === "funds" ||
      colDef.field === "fundTypes" ||
      colDef.field === "createdBy" ||
      colDef.field === "createdOn" ||
      colDef.field === "updatedBy" ||
      colDef.field === "updatedAt"
    ) {
      return {
        ...rest,
        renderHeaderFilter: CustomDropDownHeaderFilter,
      };
    } else if (colDef.field === "authName") {
      return {
        ...rest,
        renderHeaderFilter: CustomDropDownHeaderFilter,
        cellClassName: (params: GridCellParams<any, number>) => {
          return "project-name-cell";
        },
      };
    } else if (colDef.field === "upperLimit" || colDef.field === "lowerLimit") {
      return {
        ...rest,
        valueFormatter: (params: any) => {
          return (
            params &&
            `$${Number(params).toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`
          );
        },
        renderHeaderFilter: CustomTextHeaderFilter,
      };
    } else {
      return {
        ...rest,
        ...valueFormatter,
        renderHeaderFilter: CustomTextHeaderFilter,
      };
    }
  });

  const handleCellClick = async (params: any) => {
    if (params.field === "authName") {
      props.type("Edit");
      await props.addApproverLevel();
      console.log(params.row);
      props.updateRow({
        id: params.row.id,
        fundType: params.row?.fundType,
        authCode: params.row?.authCode,
        approverLevels: params.row?.approverLevels
          ?.split(",")
          .map((item: string) => item.trim()),
        funds: params.row?.funds?.split(",").map((item: string) => item.trim()),
        upperLimit: params.row?.upperLimit,
        lowerLimit: params.row?.lowerLimit,
        meetsBudget:
          params.row?.meetsBudget === "Yes"
            ? true
            : params.row?.meetsBudget === "No"
            ? false
            : undefined,
        isAmtHigherThanValuationPercentage:
          params.row?.isAmtHigherThanValuationPercentage === "Yes"
            ? true
            : params.row?.isAmtHigherThanValuationPercentage === "No"
            ? false
            : undefined,
        termLower: params.row?.termLower,
        termUpper: params.row?.termUpper,
        premiseLower: params.row?.premiseLower,
        premiseUpper: params.row?.premiseUpper,
        order: params.row?.order,
        ecoTerms:
          params.row?.ecoTerms === "Yes"
            ? true
            : params.row?.ecoTerms === "No"
            ? false
            : undefined,
        terminationFee: params.row?.terminationFee,
        terminationTime: params.row?.terminationTime,
        marketRate:
          params.row?.marketRate === "Yes"
            ? true
            : params.row?.marketRate === "No"
            ? false
            : undefined,
        isActive: params.row.isActive === "Yes",
      });
    }
  };

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Button
            variant="contained"
            className="primary"
            onClick={() => {
              props.addApproverLevel();
              props.type("Add");
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Add
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton variant="contained" className="primary" loading = {excelLoading} onClick={exportExcel}>
            <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
            EXCEL
          </LoadingButton>
        </Grid>
      </Grid>
      <Box className="dashboard-table-wrapper">
        <div style={{ width: "100%" }}>
          <DataGridPro
            className="dashboard-dataGrid"
            slots={{
              loadingOverlay: GridLoadingOverlay,
            }}
            sx={{
              pointerEvents: props.loading ? 'none' : 'auto',
              opacity: props.loading ? 0.7 : 1,
            }}
            getRowId={(row) => row.id}
            rows={rowData ?? []}
            columns={columns}
            headerFilters
            columnHeaderHeight={48}
            headerFilterHeight={48}
            pageSizeOptions={[25, 50, 100, 200]}
            rowHeight={40}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={props.loading}
            pagination
            disableColumnMenu={true}
            autoHeight
            onCellClick={handleCellClick}
            hideFooter={false}
          />
        </div>
      </Box>
    </>
  );
};

export default ApproverLevelDataGrid;
