import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type authorizationAmountByAm = {
    authorizationAmountByAm: string,
};
type formType = {
    formType: boolean
}
type leaseData = {
    leaseTerms: number,
    leasePremises: number,
    leaseamount: number
}

type InitialState = {
    amountChangeByAm : authorizationAmountByAm,
    leaseData: leaseData,
    formType: boolean,
    data: any
}

const initialState: InitialState = {
    amountChangeByAm: {authorizationAmountByAm:""} as authorizationAmountByAm,
    leaseData: { leaseTerms: 0, leasePremises: 0, leaseamount: 0 } as leaseData,
    formType: false,
    data : ""
}

const commonSlice = createSlice({
    name: "commonSlice",
    initialState,
    reducers: {
        authorizationAmountChangeByAm(state, action) {
            state.amountChangeByAm = action.payload;
        },
        leaseDataForApprovers(state, action){
            state.leaseData = action.payload
        },
        capitalAuthFormType(state, action){
            state.formType = action.payload
        },
        ecoTermChange(state, action) {
            state.data = action.payload
        }
    },
});

export const { authorizationAmountChangeByAm ,leaseDataForApprovers, capitalAuthFormType, ecoTermChange} = commonSlice.actions;

export default commonSlice.reducer;