import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useEffect } from "react";
import { getFormDataById } from "../redux/slices/getFormDataSlice";
import { ThemeProvider } from "styled-components";

import { theme } from "../components/config/Config";

import Header from "../components/shared/Header";
import { CircularProgress } from "@mui/material";
import Investment from "../components/shared/Investment";
import VTSInformation from "../components/shared/VTSInformation";
import LeaseProjectDetails from "../components/shared/LeaseProjectDetails";
import Attachments from "../components/shared/Attachments";
import Authorization from "../components/shared/Authorization";
import CapAuthActionButtons from "../components/actionButtons/ActionButtons";
import JVAuthReason from "../components/shared/JVAuthReason";
import Loader from "../components/utils/Loader";

const LeaseAuthorization = () => {
    const formData = useAppSelector((state) => state.getFormData);
    const { id } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getFormDataById(id))
        }
    }, [id]);

    return (
        <>
            {!formData?.loading ? <div className="form-dv form-field-label">
                <ThemeProvider theme={theme}>
                    <Header />
                    {formData?.formData?.vtsID !== null && formData?.formData?.vtsID !== undefined && formData?.formData?.vtsID?.length > 0 ?
                        <VTSInformation />
                        : null}
                    <Investment />
                    {formData?.formData?.showAuthSection ? <JVAuthReason /> : null}
                    <LeaseProjectDetails />
                    <Attachments />
                    <Authorization authType = "LeaseAuth"/>
                    <CapAuthActionButtons />
                    <Loader/>
                </ThemeProvider>

            </div> : <div className="loading-backdrop loading-backdrop-full">
                <CircularProgress />
            </div>}
        </>
    );
};

export default LeaseAuthorization;