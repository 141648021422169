import React, { useEffect } from 'react';
import { useAppState, useLocalState } from './authorizationSectionHelper/useStateHooks';
import { useAuthorizationConstants } from './authorizationSectionHelper/useAuthorizationConstants';

const ModAuthSection = (props: any) => {
    const appState = useAppState();
    useEffect(() => {
        if (props?.constants.isAssetManagerWithEditAccess  && appState.formData?.showTiaaOwnershipAmt ) {
            if (props?.constants.isModEcotermValid) {
                props.localState.authApproversRequest.ecoTerms = appState?.ModProjectDetailData.ecoTerms === "Yes" ? true :
                    appState?.ModProjectDetailData.ecoTerms === "No" ? false :
                        null;
                props.fetchApprovers();
            }
            else {
                props?.localState.setShowApprovers(false);
            }
        }
    }, [appState?.ModProjectDetailData.ecoTerms]);

    useEffect(() => {
        if (props?.constants.isAssetManagerWithEditAccess && !appState.formData?.showTiaaOwnershipAmt) {
            if ( props?.constants.isModAuthAmountValid) {
                props.localState.authApproversRequest.amount = appState?.ModProjectDetailData.authAmount;
                props.fetchApprovers();
            }
            else {
                props?.localState.setShowApprovers(false);
            }
        }
    }, [appState?.ModProjectDetailData.authAmount]);

    return null;
};

export default ModAuthSection;